
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





















































































































































































































































































































































































































































































































.step-header {
  display: flex;
  align-items: baseline;

  @media screen and (max-width: 720px) {
    display: block;
  }

  .step-title {
    max-width: 800px;

    @media screen and (max-width: 720px) {
      max-width: 100%;
    }
  }
}

.feedback {
  .message {
    display: flex;
    align-items: center;

    .success {
      color: $c-dark-green;
      display: flex;
      font-size: 2rem;
      margin-left: 5px;
    }

    .check-success__icon {
      fill: $c-dark-green;
      flex: 0 0 auto;
    }
  }
}

.step-pictos {
  display: flex;
  margin-bottom: 1em;
  justify-content: end;

  @media screen and (max-width: 720px) {
    margin-top: 15px;
    margin-left: 0;
  }

  :hover {
    cursor: pointer;
  }

  img {
    margin-right: 15px;
  }
}

.info__green {
  background-color: $c-light-green;
  padding: 25px;
  margin-bottom: 30px;
  border-left: 4px solid $c-dark-green;

  .blockTitle {
    color: $c-dark-green;
    display: flex;

    .text {
      font-size: 1.5em;
      margin-left: 1em;
    }

    svg.install-info__icon {
      fill: $c-dark-green;
    }
  }
}

.attention__red {
  background-color: $c-orange-lighter;
  padding: 25px;
  margin-bottom: 30px;
  border-left: 4px solid $c-orange;

  .blockTitle {
    color: $c-orange;
    display: flex;

    .text {
      font-size: 1.5em;
      margin-left: 1em;
    }

    svg.install-attention__icon {
      fill: $c-orange;
    }
  }
}

.estimate-text {
  font-size: 1.2em;
  font-weight: bold;
  color: $c-orange;
}

.orange-text {
  font-weight: bold;
  color: $c-orange;
}

.info-text {
  font-weight: bold;
}

.myresa-btn {
  width: 250px;
  margin-left: 0 !important;
}
