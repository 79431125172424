
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        























































































































































































































































































@import '~leaflet/dist/leaflet.css';
strong {
  display: inline-flex;
  font-weight: bold;
  margin-bottom: 15px;
  /*color: $c-orange;*/
}

.text-gray {
  color: $c-gray-darker;
}

.submit__form {
  margin-left: auto;
}

.row__switcher {
  display: flex;
  align-content: center;

  @media screen and (max-width: 960px) {
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
  }
}

.map__btn__container {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: max-content;
  background: $c-white;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset,
    0px 0px 8px rgba(0, 0, 0, 0.2);
}

.map__bnt {
  margin-left: auto;
  margin-right: auto;
}

.map__txt {
  margin: 15px;
}

.map {
  z-index: 1;
  width: 100%;
  height: 67rem;
  max-height: 74.5rem;
  background: $c-gray-lightest url('/assets/images/loader.svg') no-repeat center;
  ::v-deep {
    .leaflet-pane {
      z-index: 1;
    }
  }
  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (373 / 840) * 100%;
  }
}
.connection-page .switcher {
  ::v-deep {
    @media screen and (min-width: 720px) {
      margin-top: -0.5em;
    }

    .list__item {
      font-size: 13px;
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}
.connection-page .switcher {
  ::v-deep {
    .slider {
      border-radius: 3px;
    }
  }
}

.input-area {
  ::v-deep .input__field {
    &:disabled {
      padding-left: 0em;
      color: $c-black;
      background: transparent;
    }
  }
}
