
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        












































.outages-add-address {
  width: 100%;

  .submit-button {
    margin-left: auto;
    display: flex;
    border-radius: 20px;
  }
}
