
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



































.header,
[class*='header--'] {
  @include fluid(
    padding-top,
    (
      xxs: 80px,
      xl: 160px,
    )
  );

  position: relative;
  display: flex;
  align-items: center;

  &[class*='--normal'],
  &[class*='--cover'] {
    // prettier-ignore
    @include fluid(padding-top, (xxs: 40px, xl: 100px));
    // prettier-ignore
    @include fluid(padding-bottom, (xxs: 100px, xl: 85px));
  }

  &[class*='--cover'] {
    @include mq(xl) {
      height: 75rem;
    }
  }
}

.content__breadcrumb {
  padding-left: 0;
}

.content__title {
  padding-left: 0;
}
.content__fix {
  width: 100%;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem;

  ::v-deep .video {
    // prettier-ignore
    @include fluid(margin-top, (xs: 10px, xl: 60px));
  }

  .header--normal & {
    @include mq(xl) {
      flex-basis: 45%;
      max-width: 45%;
    }
  }

  .header--cover & {
    @include mq(xl) {
      flex-basis: 60%;
      max-width: 60%;
    }
  }

  @include mq(s) {
    margin-bottom: 0;
  }
}

.picture {
  width: 100%;

  img {
    display: block;
    max-width: 100%;
    max-height: 30rem;
    margin: 0 auto;
  }

  [class*='header--'][class*='--cover'] & {
    @include get-all-space;

    z-index: -1;

    img {
      @include image-fit($position: right);

      max-height: none;
      margin: 0;
    }

    &::before {
      @include get-all-space;

      content: '';
      z-index: 1;
      background-image: linear-gradient(
        145deg,
        rgba($c-white, 0.9) 60%,
        $c-transparent
      );
    }
  }

  @include mq(s) {
    max-width: none;

    img {
      max-height: none;
      margin: 0;
    }

    [class*='header--'][class*='--cover'] & {
      flex-basis: 100%;
      margin-left: 0;

      &::before {
        background-image: linear-gradient(
          to right,
          $c-white 50%,
          $c-transparent
        );
      }
    }
  }
}
