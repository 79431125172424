
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        








































































































































































































































































































































































































































































































































































































.borne-choices {
  position: relative;
}
.choice {
  cursor: pointer;
  background-color: $c-gray-lightest;
  border-radius: $border-radius;
  margin-right: 25px;
  margin-top: 50px;
  text-align: center;
  flex-basis: 23%;
  max-width: 23%;
  height: 400px;
  padding: 2em;

  .choice__title {
    color: $c_dark-green;
    font-size: 1.7em;
    font-weight: bold;

    p {
      font-size: 0.7em;
    }
  }

  .choice__img {
    display: inline-block;
    margin-bottom: 10px;
  }

  .choice__content_list__item {
    margin-top: 10px;

    &__red {
      margin-top: 10px;
      color: $c_red;
    }
  }

  .choice__picto__recommended {
    position: absolute;
    top: 0;
    padding: 10px;
  }

  @media screen and (max-width: 1600px) {
    flex-basis: 35%;
    max-width: 35%;
  }

  @media screen and (max-width: 960px) {
    margin-right: 0;
    max-width: 100%;
    flex-basis: 100%;
    height: 300px;
  }
}

.choice__selected {
  background-color: $c-white;
  border: 3px solid $c-dark-green;

  .choice__picto__recommended {
    background-color: $c-white;
  }
}

.simplicity .choice__picto__recommended {
  left: 18%;

  @media screen and (max-width: 1600px) {
    left: 29%;
  }

  @media screen and (max-width: 960px) {
    left: 89%;
  }

  @media screen and (max-width: 720px) {
    left: 78%;
  }
}

.confort .choice__picto__recommended {
  left: 43%;

  @media screen and (max-width: 1600px) {
    left: 66%;
  }

  @media screen and (max-width: 960px) {
    top: 25%;
    left: 89%;
  }

  @media screen and (max-width: 720px) {
    left: 78%;
  }
}

.confort_plus .choice__picto__recommended {
  left: 68%;

  @media screen and (max-width: 1600px) {
    top: 50%;
    left: 29%;
  }

  @media screen and (max-width: 960px) {
    left: 89%;
  }

  @media screen and (max-width: 720px) {
    left: 78%;
  }
}

.luxe .choice__picto__recommended {
  left: 92%;

  @media screen and (max-width: 1600px) {
    top: 50%;
    left: 66%;
  }

  @media screen and (max-width: 960px) {
    top: 75%;
    left: 89%;
  }

  @media screen and (max-width: 720px) {
    left: 78%;
  }
}

.info__green {
  background-color: $c-light-green;
  padding: 25px;
  margin-bottom: 30px;
  border-left: 4px solid $c-dark-green;

  .blockTitle {
    color: $c-dark-green;
    display: flex;

    .text {
      font-size: 1.5em;
      margin-left: 1em;
    }

    svg.install-info__icon {
      fill: $c-dark-green;
    }
  }
}

.attention__red {
  background-color: $c-orange-lighter;
  padding: 25px;
  margin-bottom: 30px;
  border-left: 4px solid $c-orange;

  .blockTitle {
    color: $c-orange;
    display: flex;

    .text {
      font-size: 1.5em;
      margin-left: 1em;
    }

    svg.install-attention__icon {
      fill: $c-orange;
    }
  }
}

.block-alert {
  margin-top: 15px;
  border: 1px solid $c_orange;
  border-radius: $border-radius;
  padding: 1em;
}
