
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        















.inner {
  display: flex;
  flex-direction: column-reverse;

  @include mq(xs) {
    flex-flow: row wrap;
  }

  @include mq(wrapper) {
    margin-left: col(2, 24);
  }
}

.cta-home {
  ::v-deep {
    gap: 25px 25px;
    @include fluid(
      margin-top,
      (
        xl: 50px,
        xxl: -80px,
      )
    );
    .row-title {
      margin-bottom: 0;
      .icon {
        display: none;
      }
    }
    .row-double {
      display: flex;
      flex-flow: row nowrap;
      flex-direction: row;
      align-items: center;
      max-width: 100%;
    }
    .content__icon {
      margin-bottom: 0;
    }
    .content__title {
      @include fluid(
        font-size,
        (
          xxs: 23px,
          m: 26px,
          xl: 19px,
          xxl: 21px,
          xxxl: 26px,
        )
      );
      padding-left: 5px;
      text-transform: uppercase;
    }
    .footer__icon {
      display: none;
    }
    .content__text {
      display: flex;
      margin-top: 25px;
      order: 3;
    }
    @media (min-width: 961px) {
      .content__text {
        align-self: flex-start;
      }
    }
    .content__link {
      color: $c-main-primary;
      display: flex;
      line-height: 1.2;
      margin-top: 5px;
      order: 4;
      text-decoration: underline;
    }
    @media (min-width: 961px) {
      .content__link {
        align-self: flex-start;
      }
    }
  }
}
.item {
  //flex-basis: 100%;
  width: 100%;
  &:nth-child(1),
  &:nth-child(2) {
    border-bottom: 1px solid $c-gray-light;
  }
  /*
  @include mq(xs) {
    //flex-basis: 50%;
    width: calc(50% - 13px);
    &:nth-child(1) {
      border-right: 1px solid $c-gray-light;
    }

    &:nth-child(1),
    &:nth-child(2) {
      border-top: 1px solid $c-gray-light;
      border-bottom: 0;
    }
  }
  */
  @include mq(m) {
    width: calc(50% - 13px);
    &:nth-child(1) {
      border-right: 1px solid $c-gray-light;
    }
    &:nth-child(1),
    &:nth-child(2) {
      border-top: 1px solid $c-gray-light;
      border-bottom: 0;
    }
  }
  @include mq(xl) {
    //flex-basis: 25%;
    width: calc(25% - 19px);
    &:nth-child(1) {
      border-left: 1px solid $c-gray-light;
    }

    &:nth-child(1),
    &:nth-child(2) {
      border-bottom: 1px solid $c-gray-light;
    }
  }
  /*
  @include mq(wrapper) {
    flex-basis: col(5, 22);

    &:nth-child(1),
    &:nth-child(2) {
      flex-basis: col(6, 22);
    }
  }
  */
}
