
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        
































.breadcrumb + .m-xl {
  margin-top: 0;
}

::v-deep.step-nav {
  .list__item__label {
    font-weight: bold;
  }
}

::v-deep h2 {
  color: $c_orange;
}

::v-deep .step-btn-area {
  display: flex;
  max-width: 55em;

  .btn-next {
    margin-left: auto;
  }

  @media screen and (max-width: 720px) {
    width: 100%;
    display: block;
    margin-top: 40px;

    .btn-next {
      width: 100%;
      margin-left: 0;
      margin-top: 15px;
    }

    .btn-prev {
      width: 100%;
    }
  }
}
