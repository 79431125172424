
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        







.guidance {
  // prettier-ignore
  // @include fluid(padding-left, (xxs: 20px, xxl: 64px));
  // prettier-ignore
  // @include fluid(padding-right, (xxs: 20px, xxl: 80px));
  // @include fluid(padding-bottom, (xxs: 30px, xxl: 80px));

  ::v-deep h1 {
    text-transform: none;
  }

  ::v-deep h2 {
    color: $c-main-primary;
    @include fluid(
      font-size,
      (
        xxs: 28px,
        m: 30px,
      )
    );
  }

  ::v-deep h3 {
    font-weight: 400;
    @include fluid(
      font-size,
      (
        xxs: 16px,
        m: 18px,
      )
    );
  }

  ::v-deep .switcher {
    display: flex;
    align-items: center;
  }

  ::v-deep .switcher--dual {
    border-radius: 5px;
    margin-top: 10px;
    padding: 3px 5px;
    @include mq(xs) {
      margin-top: 0px;
    }
  }
  ::v-deep .switcher--dual .slider {
    border-radius: 3px;
    padding-left: 25px;
    padding-right: 25px;
  }
  ::v-deep .switcher .list__item {
    padding-left: 25px;
    padding-right: 25px;
  }
  ::v-deep .check-block {
    max-width: inherit;
    padding-bottom: 20px;
    padding-top: 20px;
    &.is-active {
      border: 1px solid $c-orange;
      .content__copy__title,
      .content__copy__text {
        color: $c-orange;
      }
    }
    .content {
      padding-right: 5px;
    }
  }

  ::v-deep .nav {
    display: flex;
    .guidance-next {
      margin-left: auto;
      // padding: 1.5rem 1.5rem;
    }
  }
}
