
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        










































.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 3px solid $c-black;
}

.connection-page .nav {
  border-top: 1px solid #bdbdbd;
}
.nav__back {
  ::v-deep {
    .action__label {
      color: $c-gray;
    }

    .icon {
      margin-right: 0;
      fill: $c-gray;
    }
  }
}

.nav__forward {
  border-radius: 30px;
  margin-left: auto;
  padding: 1.5rem 2.2rem;
}

.connection-page {
  ::v-deep {
    .switcher {
      border-radius: 5px;
      padding: 3px 5px;
      margin-left: 2em;

      @media screen and (max-width: 960px) {
        margin-left: 0em;
      }
    }

    @media screen and (max-width: 960px) {
      margin: 1em 0 0 0;
    }
  }
}

.connection-page {
  ::v-deep {
    .switcher .slider {
      border-radius: 3px;
    }
  }
}

.connection-page {
  ::v-deep {
    .switcher .slider {
      border-radius: 3px;
    }
  }
}
