
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        






















































































































































































































.margin-right {
  margin-right: 30px;
}

.connection-configuration-modif-power {
  ::v-deep .link-block {
    margin-right: 10px;
  }
}

.check-block {
  &.is-active {
    border: 1px solid $c-orange;
    ::v-deep {
      .content__copy .content__copy__title.h4 {
        color: $c-orange;
      }
    }
  }
}
