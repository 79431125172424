
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        









































.my-resa {
  min-height: 50vh;
}

.content__title {
  color: $c-yellow;

  b {
    font-weight: 600;
  }
}

.contact-form-page {
  position: relative;

  .container-sticky {
    right: 4.1666666667%;

    @media only screen and (min-width: 85em) {
      right: 8.3333333%;
    }
  }

  ::v-deep {
    .main-title__title {
      // prettier-ignore
      @include fluid(font-size, (xxs: 28px, m: 48px));
    }

    .accordion {
      padding: 0 !important;
    }
  }
}
