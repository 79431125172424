
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        













































.situation {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}
.situation-input {
  width: 45%;
  margin-bottom: 50px;
}
.situation-input-name {
  width: 45%;
}
.situation-container {
  width: 100%;
  h2 {
    margin-bottom: 50px;
  }
  h3 {
    margin-bottom: 20px;
  }
  h1 {
    font-size: 24px;
    line-height: 1.1;
    margin-bottom: 5rem;
    font-weight: bold;
  }
  h2 {
    color: #e84e0f;
    font-size: 20px;
    line-height: 1.1;
    font-weight: bold;
    text-transform: uppercase;
  }
  @media only screen and (max-width: 600px) {
    .situation {
      flex-direction: column;
    }
    .situation-input {
      width: 100%;
    }
  }
}
