
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        









































































































h1 {
  font-size: 24px;
  line-height: 1.1;
  margin-bottom: 5rem;
  font-weight: bold;
}
h2 {
  color: #e84e0f;
  font-size: 20px;
  line-height: 1.1;
  font-weight: bold;
  text-transform: uppercase;
}
.connection-date {
  h2 {
    margin-bottom: 5rem;
  }
  div {
    display: flex;
    justify-content: space-around;

    @media screen and (max-width: 1024px) {
      display: block;
    }
    @media screen and (max-width: 760px) {
      display: flex;
    }
  }
}
h4 {
  margin-top: 5rem;
}

.connection-date_week {
  margin-bottom: 5rem;
  color: #5dae99;
}
.socketType {
  height: 13rem;
  border-bottom: 0.1rem solid #d8d8d8;
  display: flex;
  align-items: center;
  margin-top: 5px;
  justify-content: space-between;
}
.socketType-info {
  margin-top: 5rem;
}
.info__icon {
  overflow: visible;
  fill: $c-orange;
  cursor: pointer;
  top: 5px;
  position: relative;
  width: 24px;
  height: 24px;
}
.check-block {
  margin-bottom: 5rem;
  display: flex;
  width: 100%;
  padding-bottom: 4%;
  padding-top: 4%;
  justify-content: space-around;
}

.check-block-group {
  margin-bottom: 0;
}

.intervalleDate {
  justify-content: left;

  ::v-deep {
    .text-gravity {
      span:has(div) {
        width: 100%;

        input.input-datepicker::placeholder {
          color: #1111117a;
        }
      }
    }
  }
}

.check-block_item {
  width: 100%;
  margin-right: 3rem;
  border-radius: 20px;
  justify-content: space-between;
}
.situation {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}
.situation-input {
  width: 45%;
}
.situation-container {
  h2 {
    margin-bottom: 5rem;
  }
  width: 100%;
}
.noMeter {
  h3 {
    color: #e84e0f;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 400;
  }
  h2 {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 760px) {
  .situation {
    flex-direction: column;
  }
  .situation-input {
    width: 100%;
  }
  .connection-date {
    div {
      flex-direction: column;
    }
  }
  .check-block {
    flex-direction: column;
  }
}

::v-deep {
}
