
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        













































strong {
  font-weight: bold;
}

.icons-warning {
  margin-right: 10px;
  fill: $c-main-primary;
  opacity: 0.3;
}

.label {
  display: flex;
  align-items: center;
  color: $c-main-primary;

  @media screen and (max-width: 720px) {
    display: block;
  }
}

.documents__list {
  list-style-type: disc;
  margin: 0 60px;

  @media screen and (max-width: 720px) {
    margin: 0 25px;
  }
}

.document {
  color: $c-black;
  font-size: 16px;
}
.connection-page.nav {
  border-top: 1px solid #bdbdbd;
}
