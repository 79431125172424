
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





















































.perm-capacity-view {
  position: relative;

  @include mq($until: m) {
    position: relative;
  }
}

.margin-neg {
  @include mq($until: m) {
    margin-bottom: -10rem;
  }
}

.perm-capacity-view-header {
  @include mq($until: m) {
    position: relative;
    z-index: 2;
  }

  .input--field--label {
    color: $c-orange;
  }
}

.perm-capacity-view-header-field {
  display: flex;
  align-items: baseline;

  .input--field {
    width: 40rem;
  }

  .action--btn--big {
    margin-left: 4rem;
  }

  .search--loader {
    align-self: center;
  }
}

.map-legend {
  display: flex;
  align-items: center;

  img {
    margin-right: 1rem;
  }

  span {
    margin-right: 4rem;
  }
}
