
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        


















.linkedin {
  margin-bottom: 2rem;
}

.linkedin__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: top;
  margin: 0;
  padding: 0;
}

.linkedin__item {
  flex: 0 0 33%;
  max-width: calc((100% / 3) - 2rem);
  margin: 0 1rem 1rem;
}
