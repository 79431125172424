
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        















































































































































































































































































































































































































































.input__tidy {
  ::v-deep {
    .input {
      width: 10%;
    }
  }
}

.field {
  width: 100%;
  display: block;
  padding: 0rem 0 0.3rem;
  font-size: 1.8rem;
  background: transparent;
  position: relative;
  border-bottom: 1px solid $c-gray-lighter;
}

.button__start {
  margin-left: 50px;
}

.check-block--small-title {
  margin-right: 20px;
}

.title__bar {
  border-bottom: 1px solid $c-black;
  margin-top: 30px;
  margin-bottom: 30px;
}

.table__sockets {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 45px;
  margin-top: 30px;

  thead {
    border-bottom: 1px solid $c-gray;
  }

  tbody:before {
    content: '.';
    color: white;
    display: block;
  }

  th {
    font-size: 1.6rem;
    text-align: left;
    width: 25%;
    color: $c-gray;
  }

  td {
    text-align: left;
    white-space: nowrap;

    &:not(:last-child) {
      padding-right: 2rem;
    }
  }

  th:last-child,
  td:last-child {
    text-align: right;
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 45px;

  thead {
    border-bottom: 1px solid $c-gray;
  }

  tbody:before {
    content: '.';
    color: white;
    display: block;
  }

  th {
    color: $c-gray-dark;
    font-family: $ff-default;
    font-size: 1.6rem;
    font-weight: 300;
    text-align: left;
    width: 25%;
  }

  td {
    text-align: left;
    white-space: nowrap;

    &:not(:last-child) {
      padding-right: 2rem;
    }
  }

  th:last-child,
  td:last-child {
    text-align: left;
  }
}

.info__specific__sockets {
  margin-bottom: 20px;
  font-size: 18px;
  display: block;
}

.total__value {
  border-bottom: 1px solid $c-gray;
  display: table;
  margin-left: auto;
  padding: 10px 0;
  width: 20%;
}

.check-block {
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 30px;

  ::v-deep {
    .content__copy .content__copy__title.h4 {
      font-family: $ff-alt;
      font-size: 18px;
      font-weight: normal;
      line-height: 1.5em;
    }
  }
  &.is-active {
    border: 1px solid $c-orange;
    ::v-deep {
      .content__copy .content__copy__title.h4 {
        color: $c-orange;
      }
    }
  }
}

.title__bar {
  border-bottom: 1px solid $c-black;
  font-family: $ff-alt;
  margin-top: 35px;
  margin-bottom: 35px;
  padding-bottom: 0.25em;
}

@media only screen and (min-width: 64em) {
  .col-l-5 {
    flex-basis: auto;
    width: calc(25% - 25px);
  }
}
