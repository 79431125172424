
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        








































































































































































































































.picto__area {
  align-items: center;
}

.address-info__icon {
  width: 4.8rem;
  height: 4.8rem;
  margin-right: 1.3rem;
  fill: $c-main-primary;
}

.meter-info__icon {
  width: 4.8rem;
  height: 4.8rem;

  &.elec {
    fill: $c-yellow;
  }
}

.recap-green {
  background-color: $c-light-green;
  padding: 25px;
  margin-bottom: 30px;
  border-left: 4px solid $c-dark-green;

  .blockTitle {
    color: $c-dark-green;
    display: flex;
    align-items: center;

    .text {
      font-size: 1.5em;
      margin-left: 1em;
      line-height: 1em;
    }

    svg.install-info__icon {
      fill: $c-dark-green;
      flex: 0 0 auto;
    }
  }
}

.recap-adresse-content p + p {
  margin-top: unset;
}
