
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

















.keypoint {
  display: inline-flex;
  border-bottom: 1px solid $c-gray-light;
}

.title {
  @extend %fw-medium;
  // prettier-ignore
  @include fluid(margin-right, (xxs: 20px, m: 50px));

  display: block;
  flex-shrink: 0;
  font-family: $ff-default;
  line-height: 1;
}

.text {
  flex: 1;
  margin-top: 1.6rem;
}
