
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        






























































































































































.outages-map-panel {
  // prettier-ignore
  // @include fluid(padding-left, (xxs: 60px, xxl: 140px));
  // prettier-ignore
  // @include fluid(padding-right, (xxs: 60px, xxl: 140px));
  // prettier-ignore
  // @include fluid(padding-top, (xxs: 50px, xxl: 100px));
  // prettier-ignore
  // @include fluid(padding-bottom, (xxs: 50px, xxl: 100px));
  .check-block {
    width: 17%;
  }
  .check_blocks {
    display: flex;
  }

  .confirm-text {
    display: flex;
    color: $c-green;
    fill: $c-green;
  }

  .text-gray {
    color: $c-gray-darker;
    display: contents;
  }

  .rgpd-mention .mt-m {
    margin-top: 10px;
  }

  @media (min-width: 1360px) {
    .mt-m {
      margin-top: 10px;
    }
  }

  .title {
    text-transform: none;
    @include fluid(
      font-size,
      (
        xxs: 32px,
        m: 42px,
      )
    );
  }

  .subtitle {
    @include fluid(
      font-size,
      (
        xxs: 32px,
        m: 50px,
      )
    );
  }

  .section {
    color: $c-main-primary;
  }

  .status {
    display: flex;
    align-items: center;
  }

  .button {
    border-radius: 20px !important;
    height: fit-content;
    padding: 1.5rem 2rem;
  }

  ::v-deep .check-block {
    border-radius: 10px;
    margin-right: 30px;
    padding: 20px;

    .content__copy .content__copy__title.h4 {
      font-family: $ff-alt;
      font-weight: normal;
      line-height: 1.5em;
    }
  }

  ::v-deep .is-active {
    border: 1px solid $c-orange;

    .content__copy .content__copy__title.h4 {
      color: $c-orange;
    }
  }

  ::v-deep .fatline::after {
    background-color: $c-main-primary;
  }
}

.phone {
  display: flex;
  width: 100%;
  color: $c-gray;

  ::v-deep .input {
    width: 100%;
  }
}

.phone__flag {
  flex-shrink: 0;
  width: 2rem;
  margin-right: 0.5rem;
}

.country-codes {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 1rem;
  padding: 0.5rem;
  border-bottom: 1px solid $c-gray-lighter;

  ::v-deep .placeholder {
    justify-content: flex-end;
  }

  ::v-deep .options {
    width: 9rem;
  }
}
