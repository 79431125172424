
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        






































.map-legend {
  display: flex;
}

.map-legend-title {
  border-bottom: 1px solid;
  h5 {
    margin-top: 0;
    margin-bottom: 1rem;
  }
}

.map-legend-icon-container {
  display: grid;
  cursor: pointer;
  height: 5rem !important;
  min-width: 5rem !important;
  align-items: center;
  justify-items: center;
}

.map-legend-elements {
  padding: 1.5rem 2rem 2.5rem 1rem;
}

.map-legend-container {
  ul {
    padding: 0;
  }

  li {
    display: flex;
    align-items: center;
  }

  svg {
    min-width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  p {
    font-size: 1.6rem;
  }
}

.map-legend-active {
  background-color: $c-gray-light;
}
