
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        






































.accordion {
  position: relative;
}
.titreFaqs {
  color: $c-green;
  font-size: 2.3rem;
  padding-bottom: 4rem;
}

::v-deep .action__label {
  font-size: 1.8rem;
}

.faqsSubpage {
  position: relative;
}
