
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





















.center {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: max-content;
}

.connection__header {
  ::v-deep .content__breadcrumb {
    padding-left: 0px;
  }
}
