
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





























































































































































.edit-modify {
  ::v-deep .check-block {
    padding: 20px;
  }
}

.check-block {
  &.is-active {
    border: 1px solid $c-orange;
    ::v-deep {
      .content__copy .content__copy__title.h4 {
        color: $c-orange;
      }
    }
  }
}

.edit-modify-select {
  padding-bottom: 50px;
  border-bottom: 2px solid $c-black;
}

.section-number {
  display: inline-block;
  height: 30px;
  width: 30px;
  border: 1px solid $c-main-primary;
  border-radius: 50%;
  background-color: $c-main-primary;
  color: $c-white;
  padding: 0.2rem 1rem;
}

.section-title {
  font-size: 2rem;
  font-weight: bold;
  color: $c-main-primary;
  margin-left: 20px;
}

.meter-label {
  align-items: center;
  font-size: 2rem;
}

.relative {
  position: relative;

  ::v-deep .container {
    top: 0px;
    right: -200px;
    height: 98%;
  }
}

.modify-configurator-meter {
  margin-left: 30px;
}

.supp-meters-counter {
  align-items: center;

  .counter {
    float: right;
    max-width: fit-content;
  }
}

.counter {
  ::v-deep {
    @media screen and (max-width: 720px) {
      margin: 1em 0 0 0;
    }

    .btn:before,
    .btn:after {
      background: $c-main-primary;
    }
    .btn:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    .btn:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
}

.depla-branchement-switcher {
  align-items: center;

  .switcher {
    margin-left: 0 !important;
  }
}

.meters-checkblocks {
  ::v-deep .content__copy__text {
    margin-top: 0;
  }
}

.color-main {
  color: $c-main-primary;
}

.margin-reset {
  margin-top: 0;
}

.font-size-20 {
  font-size: 2rem;
}

.meters-checkblocks {
  margin-right: 30px;
  &:last-child {
    margin-right: 0;
  }
  max-width: 48rem;
}

.textarea {
  margin-top: 50px;
}
