
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

















.img-title {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 2.5rem;
}

.text {
  color: #a4a4a4;
}

.orange {
  color: $c-main-primary;
}
