
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        















































































































































































.vueperslides__bullets {
  @include mq(xl) {
    bottom: 80px;
  }
}
