
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        













.loader {
  .loading {
    margin: 50px auto 20px auto;
    height: 250px;
  }

  span {
    display: table;
    margin: 0px auto;
  }
}
