
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





































































.content {
  position: relative;
}

.shedding {
  ::v-deep .check-address {
    height: fit-content;
  }
}

.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.aside {
  display: none;

  @include mq(l) {
    display: block;
  }
}

.api-response {
  text-transform: lowercase;
}
