
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        
















.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 3px solid $c-black;
}

.nav__back {
  ::v-deep {
    .action__label {
      color: $c-gray;
    }

    .icon {
      margin-right: 0;
      fill: $c-gray;
    }
  }
}

.nav__forward {
  margin-left: auto;
}

.connection__main__text {
  margin-right: 40px;
}
