
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        











.link-block,
[class*='link-block--'] {
  @extend %border-radius;
  // prettier-ignore
  @include fluid(padding-top, (xxs: 20px, m: 30px));
  // prettier-ignore
  @include fluid(padding-right, (xxs: 20px, m: 40px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 20px, m: 30px));
  // prettier-ignore
  @include fluid(padding-left, (xxs: 20px, m: 40px));

  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background: $c-white;
  border: 1px solid $c-gray-lighter;
  cursor: pointer;
  transition: all 0.3s;

  &.is-selected {
    background: $c-orange;
  }

  &:hover {
    box-shadow: 0 10px 30px rgba($c-black, 0.1);
    transform: translateY(-2px);
  }
}

.label {
  @extend %fw-bold;
  // prettier-ignore
  @include fluid(font-size, (xxs: 15px, m: 18px));

  color: $c-black;

  .is-selected & {
    color: $c-white;
  }
}

svg circle {
  fill: $c-orange;
}
