
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        




































































































































.contact-formulaire div.confirm-text {
  display: flex;
  color: $c-green;
  fill: $c-green;
}

.contact-formulaire span.text-gray {
  color: $c-gray-darker;
  display: contents;
}

.contact-formulaire div.confirm-follow {
  @include mq(m) {
    display: flex;
  }
}

.contact-formulaire h2.confirm {
  color: $c-main-primary;
}

.contact-formulaire {
  margin-bottom: 65px;
}

.contact-formulaire .fatline {
  font-size: 17px;
}

.contact-formulaire .fatline::after {
  background-color: #e84e0f;
}

.contact-formulaire div.panel-accordion {
  border-radius: 10px;
  margin-bottom: 25px;
}

.contact-formulaire div.panel-accordion div.panel-accordion-title:hover span {
  font-weight: normal;
}

.contact-formulaire
  div.panel-accordion
  div.panel-accordion-title.active
  .level-name {
  font-weight: normal;
}

.contact-formulaire div.panel-accordion div.panel-accordion-content {
  padding: 15px;
}

.contact-formulaire div.panel-accordion-content .level-list-item {
  margin-bottom: 5px;
  cursor: pointer;
}

.contact-formulaire div.panel-accordion-content .level-list-item a {
  color: #575756;
}

.contact-formulaire .confirm-button {
  margin-top: 20px;
  padding-top: 0;
  margin-bottom: 20px;
  padding-bottom: 0;
}

.contact-formulaire .send-btn-container {
  text-align: end;
}

.submit {
  @include loading($c-orange);
}
