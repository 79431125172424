
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        




















































































.inputs {
  padding: 3.5rem;
  box-shadow: $box-shadow;
  border-radius: 2rem;
  margin: 4rem 0 7rem;
  display: inline-block;
}

.nav {
  text-align: right;
}

.form {
  margin-bottom: 11rem;
  @include mq(m) {
    margin-bottom: 7rem;
  }
}

.error {
  margin-bottom: 2rem;
}

.inputs-inner {
  display: inline-block;
  width: 100%;
}

.submit {
  @include loading($c-orange);
}
