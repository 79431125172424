
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

















































































.outages-map-panel {
  // prettier-ignore
  @include fluid(padding-left, (xxs: 60px, xxl: 140px));
  // prettier-ignore
  @include fluid(padding-right, (xxs: 60px, xxl: 140px));
  // prettier-ignore
  @include fluid(padding-top, (xxs: 50px, xxl: 100px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 50px, xxl: 100px));

  .title {
    text-transform: none;
    @include fluid(
      font-size,
      (
        xxs: 32px,
        m: 42px,
      )
    );
  }

  .subtitle {
    @include fluid(
      font-size,
      (
        xxs: 32px,
        m: 50px,
      )
    );
  }

  .section {
    color: #e84e0f;
  }

  ::v-deep {
    .fatline::after {
      background-color: #e84e0f;
      height: 0.05em;
    }
  }

  .status {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 50px;
  }

  div.status span {
    margin: 0 6px 0 6px;
  }

  .icon-recharge {
    transform: translate(0, 4px);
    margin-right: 10px;
  }
}
