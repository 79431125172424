
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        























.container {
  margin: 30px 0px;

  @include mq(l) {
    position: absolute;
    height: 90%;
    top: 150px;
    right: 0;
    margin: 0;
  }
}

.guidance__tip {
  @include mq(l) {
    //margin-left: 20px;
    border: 4px solid $c-yellow;
    border-radius: 20px;
    padding: 3rem 3rem;
    position: -webkit-sticky;
    position: sticky;
    top: 150px;
  }

  .guidance__tip__button {
    @include mq($until: l) {
      width: 100%;
    }
  }

  ::v-deep .tip {
    padding: 0px;

    @include mq(l) {
      max-width: 28rem;
    }
  }

  ::v-deep .tip__text {
    @include mq($until: l) {
      color: $c-main-primary;
    }
  }

  ::v-deep .tip__icon {
    width: 2.4rem;
    height: 2.4rem;

    @include mq($until: l) {
      display: none;
    }
  }
}
