
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

































.div {
  position: absolute;
  width: 436px;
  height: 474px;
  left: 57px;
  top: 112px;

  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 43px;

  color: #575756;
}
.outages-ep-panel {
  // prettier-ignore
  @include fluid(padding-left, (xxs: 60px, xxl: 140px));
  // prettier-ignore
  @include fluid(padding-right, (xxs: 60px, xxl: 140px));
  // prettier-ignore
  @include fluid(padding-top, (xxs: 50px, xxl: 100px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 50px, xxl: 100px));
}
