
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        










































































































































































































































.owner-form {
  margin-top: 5rem;
  width: 65%;

  @media only screen and (max-width: 960px) {
    width: 100%;
  }
}

.flex {
  display: flex;
  align-items: center;

  @media only screen and (max-width: 960px) {
    display: block;
  }
}

.phone {
  display: flex;
  width: 100%;
  color: $c-gray;

  ::v-deep .input {
    width: 100%;
  }
}

.checkBlock-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
  margin-top: 2.5rem;
  ::v-deep span {
    font-size: 1.6rem;
    color: $c-gray-darker;
    font-weight: 400;
    line-height: 1.5;
  }

  ::v-deep .link-block {
    text-align: left;
  }
}

.success {
  color: $c-green;
}

.error {
  color: $c-red;
}

.content {
  margin-bottom: 50px;
}

.situation {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.situation-input {
  width: 45%;
  margin-bottom: 25px;
  @media only screen and (max-width: 960px) {
    width: 100%;
  }
}

.container {
  width: 100%;

  h3 {
    font-size: 18px;
    line-height: 1.1;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 20px;
    color: black;
  }
  h1 {
    font-size: 1.5em;
    font-weight: normal;
    margin-bottom: 2.5rem;
    text-transform: none;
  }
  h2 {
    font-size: 18px;
    line-height: 1.1;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 20px;
    color: black;
  }

  .mandatory {
    color: $c-orange;
    font-weight: bold;
  }
}
