
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        


































.bold {
  font-weight: bold;
}

.select-check-block {
  display: flex;
  cursor: pointer;

  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }
}

.content {
  display: flex;
  align-items: flex-start;
}

.content__copy__title {
  @extend %fw-bold;

  font-family: $ff-default;
}

.content__copy__text {
  // prettier-ignore
  @include fluid(font-size, (xxs: 15px, m: 18px));
}

.content__copy__link {
  font-size: 13px;
  color: $c-main-primary;
}

.select-check {
  min-width: 2.8rem;
  height: 2.8rem;
  background-color: $c-gray-lighter;
  border-radius: 50%;
  transition: background-color 0.3s;
  margin-right: 20px;

  .icon {
    display: none;
    width: 100%;
    height: 100%;
  }

  .is-active & {
    background-color: $c-orange;

    .icon {
      display: block;
      fill: $c-white;
    }
  }
}
