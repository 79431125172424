
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        
















































































.check-block {
  border-radius: 10px;
  margin-right: 30px;
  padding: 20px;
  ::v-deep {
    .content__copy .content__copy__title.h4 {
      font-family: $ff-alt;
      font-weight: normal;
      line-height: 1.5em;
    }
  }
  &.is-active {
    border: 1px solid $c-orange;
    ::v-deep {
      .content__copy .content__copy__title.h4 {
        color: $c-orange;
      }
    }
  }
}
