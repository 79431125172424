
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        















































































































































































































































































































































































































































































































































.info__wrapper {
  padding: 20px 30px;
  background-color: #fafafc;
  display: flex;

  @media screen and (max-width: 720px) {
    padding: 20px 15px;
    display: block;
  }
}

.info__svg {
  fill: $c-orange;
  width: 40px;
  height: 30px;
  margin-right: 20px;

  @media screen and (max-width: 720px) {
    width: 20px;
  }
}

.info__text {
  color: $c-gray-dark;
  font-size: 14px;
}

.terminate__final {
  width: 100%;
  align-items: center;
  display: flex;
}

.terminate__button {
  margin: 0 auto;
}

.emphase-text {
  color: #ff4b00;
}

.timeline--steps {
  padding: 0;
  margin: 0 0 0 20px;
  list-style: none;
  position: relative;
}

/*.timeline--steps:before {
  content: '';
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0%;
  transform: translateX(-50%);
  border: 2px #b1b1b1;
  border-style: none dotted none none;
}
*/

.timeline--step {
  padding-bottom: 3.5vh;
  text-align: right;
  width: 95%;
  position: relative;

  p.address {
    margin-top: 0;
    text-transform: uppercase;
  }
  &:before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -5%;
    transform: translateX(-50%);
    border: 2px #b1b1b1;
    border-style: none dotted none none;
  }
  /*&.tva:before {
    border: 0px dotted transparent !important;
    border:none;
    border-style: none none none none;
    content: unset;
    display: none;
    height: 0px;
    position: relative;
    transform: unset;
    width: 0px;
  }*/
}
.timeline--step.tva:before {
  border: none !important;
  content: unset;
  display: none;
  height: 0;
  position: relative;
  transform: unset;
  width: 0;
}
.timeline--number {
  position: absolute;
  left: -5%;
  transform: translateX(-50%);
  color: white;
  font-size: 2rem;
  display: block;
  width: 34px;
  height: 34px;
  background: black;
  text-align: center;
  border-radius: 100px;
  font-family: Martel, sans-serif;
  z-index: 1;
}

.timeline--step h4 {
  color: #ff4b00;
  display: inline-flex;
  font-family: $ff-alt;
  font-size: 20px;
  font-weight: 300;
  line-height: 35px;
  margin: 0 0 0.5em 0;
  padding-top: 2px;
}

.timeline--step p {
  margin: 0em 0 0.8em 0;
}

.timeline--step.right .timeline--number {
  background: #ff4b00 url('../../../assets/images/icons/orange-check.svg')
    center no-repeat;
}

.timeline--step.right {
  margin-left: auto;
  text-align: left;
}
.recap__meter__id {
  color: $c-gray-dark;
}

@media screen and (max-width: 760px) {
  .timeline--step {
    width: 90%;

    &:before {
      left: -10%;
    }
  }
  .timeline--header {
    min-width: 0;
    width: 100%;
  }
  .timeline--header h2 {
    font-size: 3rem;
  }
  .timeline--number {
    left: -10%;
  }
}

.wrapper_documents {
  display: flex;
  svg {
    height: 29px;
    margin-top: 0px;
    width: 29px;
  }
}

.span-elec,
.span-gaz {
  padding-left: 30px;
  background: left center url('../../../assets/svg/icons/electricityflood.svg')
    no-repeat;
  background-size: contain;
  color: #f7a600;
}

.span-gaz {
  background: left center url('../../../assets/svg/icons/gazflood.svg')
    no-repeat;
  color: #5dad98;
}

.check__svg {
  fill: $c-green;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.5rem;
}

.error__svg {
  fill: $c-red;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.5rem;
}

.recap__meter__price {
  text-align: right;
  color: $c-orange;
}

.recap__meter__name {
  color: $c-gray-light;
}

.recap__meter__name {
  color: $c-orange;
}

.align__bnt {
  margin-left: auto;
}
.timeline {
  ::v-deep {
    .align__bnt.action--outline--btn {
      border: 0;
      color: black;
      text-decoration: underline;
      max-width: 256px;
      max-height: 256px;
      background: left center url('../../../assets/svg/ui/pencil.svg') no-repeat;
      background-size: 1em;

      .action__label {
        font-size: 13px;
      }
    }
  }
}
.type {
  display: block;
  padding-bottom: 15px;
}

.loading {
  margin: 100px auto;
  height: 250px;
}
