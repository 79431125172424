
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        








































































.header {
  ::v-deep .content__title {
    color: $c-main-secondary;
    font-size: 35px;
    @include mq(m) {
      font-size: 55px;
    }
  }

  ::v-deep .slot {
    @include mq($until: m) {
      display: flex;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.flexibles {
  display: inline-block;
  @include fluid(
    margin-top,
    (
      xxs: 100px,
      xl: 20px,
    )
  );

  ::v-deep .btn {
    border-radius: 20px !important;
    padding: 1.8rem 2.8rem;
    flex-direction: row-reverse;
  }

  ::v-deep .btn__label {
    font-size: 18px !important;
    margin-left: 10px;
  }

  ::v-deep .btn__icon {
    width: 3.6rem;
    height: 3.6rem;
  }

  ::v-deep .cpt-text_title {
    font-size: 35px;
    color: #575756;
    text-align: center;
    text-transform: none;
    font-weight: 400;
    font-family: proxima-nova, sans-serif;
  }
}

// header content:

.content__title {
  b {
    font-weight: 600;
  }
}

.header,
[class*='header--'] {
  /*@include fluid(
    padding-top,
    (
      xxs: 80px,
      xl: 160px,
    )
  );*/

  position: relative;
  display: flex;
  //align-items: center;

  &[class*='--normal'] {
    // prettier-ignore
    //@include fluid(padding-top, (xxs: 40px, xl: 125px));
    // prettier-ignore
    //@include fluid(padding-bottom, (xxs: 100px, xl: 85px));

    .content__title {
      @include mq(l) {
        margin-top: 50px;
      }
    }
  }

  &[class*='--cover'] {
    @include fluid(
      padding-top,
      (
        xxs: 75px,
        xl: 120px,
      )
    );
    @include mq(xl) {
      //height: 650px;
      height: auto;
      @include mq(xxl) {
        height: 650px;
        margin-top: -125px;
        padding-top: 150px;
      }
    }
    .content {
      padding-bottom: 40px;
    }
    .content__title {
      color: $c-main-secondary;
      line-height: 1em;
      margin-top: 0;
      text-transform: none;
      @include mq(m) {
        font-size: 80px;
      }
      ::v-deep {
        b {
          color: white !important;
          //text-shadow: 0px 1px 3px  rgba(247, 166, 0, 1);
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: $c-main-secondary;
          -webkit-text-fill-color: #ffffff;
          @include mq(s) {
            -webkit-text-stroke-width: 1.7px;
          }
        }
      }
    }
    .content__text {
      line-height: 1.3;
      @include mq(l) {
        margin-top: -20px;
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //margin-bottom: 2rem;

  ::v-deep .video {
    // prettier-ignore
    @include fluid(margin-top, (xs: 10px, xl: 60px));
  }
  // .header--normal & {
  // @include mq(xl) {
  //  flex-basis: 45%;
  //   max-width: 45%;
  // }
  //}
  .header--cover & {
    @include mq(xl) {
      flex-basis: 50%;
      max-width: 50%;
    }
  }

  @include mq(s) {
    margin-bottom: 0;
  }
}

.picture {
  width: 100%;

  img {
    display: block;
    max-width: 100%;
    max-height: 30rem;
    margin: 0 auto;
  }

  [class*='header--'][class*='--cover'] & {
    @include get-all-space;
    z-index: -1;

    img {
      @include image-fit($position: right);
      max-height: none;
      margin: 0;
    }

    &::before {
      @include get-all-space;
      content: '';
      z-index: 1;
      background-image: linear-gradient(
        145deg,
        rgba($c-white, 0.9) 60%,
        $c-transparent
      );
    }
  }

  [class*='header--'][class*='--normal'] & {
    img {
      border-radius: $border-radius;
      max-height: none;
    }
    @include mq($until: s) {
      margin-top: 40px;
    }
  }
  [class*='header--'][class*='--cover'] & {
    img {
      border-radius: revert;
    }
  }
  @include mq(s) {
    max-width: none;
    margin-top: 75px;
    img {
      max-height: none;
      margin: 0;
    }

    [class*='header--'][class*='--cover'] & {
      flex-basis: 100%;
      margin-left: 0;
      margin-top: 0;
      &::before {
        background-image: linear-gradient(
          to right,
          $c-white 50%,
          $c-transparent
        );
      }
    }
  }
  @include mq(xl) {
    margin-top: 0px;
  }
}

.preview {
  @include get-all-space;

  z-index: -1;

  &::before {
    @include get-all-space;
    content: '';
    z-index: 1;
    background-image: linear-gradient(
      145deg,
      rgba($c-white, 0.7) 60%,
      $c-transparent
    );
  }

  .preview__video {
    @include image-fit($position: center);
    max-height: none;
    margin: 0;
  }
}

.slot {
  @include mq(m) {
    margin-left: auto;
  }
}

// btn header:

a.action,
[class*='action--'] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: $c-main-primary;
  line-height: 1;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
  .cta-home & {
    @include mq($until: s) {
      display: flex;
      flex: 1;
      width: 100%;
    }
  }
}

// Links
[class*='action--'][class*='--rounded'] {
  @extend %fw-medium;
  @extend %border-radius;

  // prettier-ignore
  @include fluid(padding-top, (xxs: 20px, m: 30px));
  // prettier-ignore
  @include fluid(padding-right, (xxs: 20px, m: 40px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 20px, m: 30px));
  // prettier-ignore
  @include fluid(padding-left, (xxs: 20px, m: 40px));

  justify-content: space-between;
  background: $c-white;
  border: 1px solid $c-gray-light;
  transition: all 0.3s;

  &:hover {
    border: 1px solid $c-white;
    box-shadow: 0 10px 30px rgba($c-black, 0.1);
    transform: translateY(-2px);
  }
}

[class*='action--'][class*='--link'] {
  color: $c-default;
  text-decoration: none;

  &[class*='--negative'] {
    color: $c-white;
  }

  &[class*='--dark'] {
    color: $c-black;
  }

  &[class*='--reverse'] {
    display: flex;
    flex-direction: row-reverse;
  }
}

// Buttons (tag OR .btn)
// button.action,
// button[class*='action--'],
a.action.btn,
[class*='action--'][class*='--btn'] {
  padding: 1rem 2rem;
  color: $c-white;
  font-size: 1.3rem;
  text-decoration: none;
  background-color: $c-main-primary;
  border: 0;
  border-radius: 99em;
  transition: background-color 0.15s, color 0.15s, border 0.15s;

  &:disabled {
    opacity: 0.5;
  }

  /* stylelint-disable-next-line selector-max-pseudo-class */
  &:hover:not(:disabled):not([class*='--outline']):not([class*='--negative']) {
    cursor: pointer;
    background-color: darken($c-main-primary, 3);
  }

  &[class*='--rectrounded'] {
    border-radius: 1em;
  }

  &[class*='--whiteborder'] {
    border: 1px solid $c-white;
  }

  &[class*='--yellow'] {
    background-color: $c-main-secondary;

    &:hover:not(:disabled):not([class*='--outline']):not(
        [class*='--negative']
      ) {
      background-color: darken($c-main-secondary, 3);
    }
  }

  &[class*='--orange'] {
    background-color: $c-main-primary;

    &:hover:not(:disabled):not([class*='--outline']):not(
        [class*='--negative']
      ) {
      background-color: darken($c-main-primary, 3);
    }
  }

  &[class*='--green'] {
    background-color: $c-green;

    &:hover:not(:disabled):not([class*='--outline']):not(
        [class*='--negative']
      ) {
      background-color: darken($c-green, 3);
    }
  }

  &[class*='--big'] {
    padding: 1.8rem 2.8rem;
    font-size: 1.5rem;
    border-radius: 35px;

    @include mq('m') {
      font-size: 2rem;
    }

    &[class*='--rectrounded'] {
      border-radius: 1em;
    }
  }

  &[class*='--collapsed'] {
    padding-right: 1rem;
  }

  &[class*='--negative'],
  &[class*='--outline'] {
    border-width: 1px;
    border-style: solid;
  }

  &[class*='--negative'] {
    background-color: transparent;
    color: $c-main-primary;

    &[class*='--yellow'] {
      color: $c-main-secondary;
    }

    &[class*='--green'] {
      color: $c-green;
    }

    &[class*='--orange'] {
      color: $c-main-primary;
    }
  }

  &[class*='--outline'] {
    color: $c-main-primary;
    background-color: $c-white;
    border-color: $c-gray-light;

    &:hover:not(:disabled) {
      background-color: $c-white;
      border-color: $c-main-primary;
    }
    &.header__nav__myresa {
      border-color: $c-main-primary;
      padding: 0.75rem 8px 0.75rem 8px;
    }
  }

  &[class*='--reverse'] {
    flex-direction: row-reverse;
  }

  @include mq('m') {
    font-size: 1.5rem;
  }
}

/* stylelint-disable-next-line block-no-empty */
.action__label {
  @extend %fw-medium;
  // prettier-ignore
  @include fluid(font-size, (xxs: 13px, m: 15px));

  transition: color 0.15s, border 0.15s;

  [class*='action--'][class*='--fixed'] & {
    font-size: 1.3rem;
  }

  [class*='action--'][class*='--underline'] & {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $c-main-primary;
  }

  [class*='action--'][class*='--link']:not([class*='--btn']) & {
    color: $c-main-primary;
  }

  &[class*='--negative'] {
    color: $c-main-secondary;
  }

  [class*='action--'][class*='--link'][class*='--negative'] & {
    &[class*='--yellow'] {
      color: $c-main-secondary;
    }

    &[class*='--green'] {
      color: $c-green;
    }

    &[class*='--orange'] {
      color: $c-main-primary;
    }
  }

  [class*='action--'][class*='--link'][class*='--dark'] & {
    color: $c-black;
  }

  [class*='action--'][class*='--link'][class*='--dark']:hover & {
    color: $c-black;
  }

  /* stylelint-disable no-descending-specificity */
  [class*='action--'][class*='--underline'][class*='--negative'] & {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $c-white;
  }

  [class*='action--'][class*='--underline'][class*='--dark'] & {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $c-black;
  }

  [class*='action--'][class*='--underline'][class*='--dark']:hover & {
    border-bottom: 1px solid $c-orange;
  }
  /* stylelint-enable no-descending-specificity */
}

.action__icon-wrapper {
  margin-left: 0.75em;

  /* stylelint-disable no-descending-specificity */
  [class*='--reverse'] & {
    margin-right: 0.75em;
    margin-left: 0;
  }

  [class*='action--'][class*='--icon'][class*='--arrow'] & {
    overflow: hidden;
  }
  /* stylelint-enable no-descending-specificity */
}

.action__icon-wrapper-sup {
  overflow: hidden;
  margin-right: 0.75em;
}

.action__icon {
  color: inherit;
  vertical-align: middle;
  transition: transform 0.15s ease-in-out;
  fill: currentColor;

  [class*='--link'] & {
    fill: $c-main-primary; // Different icon color when used within link
  }

  [class*='--dark'] & {
    fill: $c-black;
  }

  [class*='--btn'] & {
    fill: $c-white;
  }

  [class*='--negative'] &,
  [class*='--outline'] & {
    fill: currentColor;
  }

  [class*='--collapsed'] & {
    width: 3.2rem;
    height: 3.2rem;
    margin-top: -0.4rem;
    margin-right: -0.4rem;
    margin-bottom: -0.4rem;
  }

  [class*='--static'] & {
    :hover:not(:disabled) > & {
      transform: none;
    }
  }

  /* stylelint-disable no-descending-specificity */
  // [class*='--reverse'] & {
  //   :hover:not(:disabled) > & {
  //     transform: translateX(-0.3rem);
  //   }
  // }

  [class*='action--'][class*='--no-animation']:hover & {
    transform: none !important; /* stylelint-disable-line declaration-no-important */
    animation: none !important; /* stylelint-disable-line declaration-no-important */
  }

  [class*='action--']:hover:not(:disabled) & {
    transform: translateX(0.3rem);
  }

  [class*='action--'][class*='--arrow']:hover:not(:disabled) & {
    animation: arrow linear 0.3s;
    transform: none;
  }

  [class*='action--'][class*='--icon'][class*='--download'] &,
  [class*='action--'][class*='--icon'][class*='--icons-profile'] & {
    width: 1.4rem;
    height: 1.4rem;
  }
  [class*='action--'][class*='--icon'][class*='--warning'] & {
    width: 20px;
    height: auto;
  }

  [class*='--collapsed'][class*='--big'] & {
    width: 4.4rem;
    height: 4.4rem;
    margin: -1.2rem -0.4rem -1.2rem 1.2em;

    @include mq('m') {
      width: 5.4rem;
      height: 5.4rem;
    }
  }

  .action__icon-wrapper-sup & {
    :hover:not(:disabled) > & {
      transform: none !important; /* stylelint-disable-line declaration-no-important */
    }
  }
  /* stylelint-enable no-descending-specificity */
}

@keyframes arrow {
  50% {
    transform: translateX(100%);
  }

  50.01% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}
