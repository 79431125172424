
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





































































.locator-panel {
  overflow: auto;
  padding: 3rem;
  background: $c-white;

  @include mq(m) {
    padding: 9rem 10rem;
  }
}

.headline {
  margin-bottom: 1.5rem;
  color: $c-orange;
}

.title {
  margin-bottom: 2rem;
}

.address {
  margin-bottom: 4.5rem;
  color: $c-gray-dark;
}

.schedule {
  @extend %fw-bold;
}

.table {
  width: 100%;
  margin: 2rem 0;
  padding: 3rem 0;
  border-top: 1px solid $c-gray-dark;
  border-bottom: 1px solid $c-gray-dark;

  td {
    text-align: right;
    white-space: nowrap;

    &:not(:last-child) {
      padding-right: 2rem;
    }
  }
}

.day {
  width: 70%;
  text-align: left !important; /* stylelint-disable-line declaration-no-important */
}
