
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        




































































































.table {
  @media screen and (min-width: 720px) {
    background-color: #f8f8f8;
    padding: 4rem 0rem;
  }
}

.table-desktop {
  position: relative;
  table {
    border-collapse: collapse;
    width: 100%;
  }

  .align-center {
    display: flex;
    justify-content: center;
  }

  thead {
    text-align: left;
  }

  tr.table-item td {
    border-bottom: 1px solid $c-gray-darker;
  }

  tr > td {
    padding: 1.5rem 0;
  }
  td.align-right {
    display: grid;
    justify-items: right;
  }
}

.table-mobile-items {
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 2rem;

  p {
    margin-top: 0;
    font-size: 1.8rem;
  }
}

.table-mobile-item {
  padding: 3rem;

  &:not(:last-child) {
    border-bottom: 1px solid #b6b6b6;
  }

  .button {
    width: 100%;
    padding: 1.7rem 2.8rem;
    border-radius: 20px;

    ::v-deep .action__label {
      font-size: 1.5rem;
    }
  }
}

.table-mobile-item-container {
  display: flex;

  .bold {
    font-weight: bold;
  }
}

.button {
  padding: 1rem 2rem;
  border-radius: 20px !important;
}

.table-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.not-outages {
  display: flex;
  justify-content: center;
}
