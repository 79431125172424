
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



























.map-container {
  display: flex;
  height: 700px;
  width: 100%;
}
.map-button_icon {
  margin-top: 5px;
}

.map-button {
  width: 33px;
  height: 33px;
  line-height: 30px;
  position: absolute;
  margin-left: 0.9em;
  margin-top: 7em;
  z-index: 800;
  display: flex;
  background-color: white;
  border: 2px solid #ccc;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @include mq($until: m) {
    margin-left: 1.5em;
    margin-top: 10em;
  }
}

.map-legend {
  @include mq($until: m) {
    position: absolute;
    z-index: 3;
    background: $c-white;
    border-radius: 25px;
    opacity: 0.9;
    bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    left: 5rem;
    right: 5rem;

    ::v-deep .map-legend-icon-container {
      display: none;
    }

    ::v-deep .map-legend-elements {
      padding: 1rem;
      margin-left: auto;
      margin-right: auto;
    }

    ::v-deep .map-legend-title {
      display: none;
    }

    ::v-deep .map-legend-container {
      margin-top: 0;
    }

    ::v-deep .map-legend-items {
      display: flex;
      justify-content: left;
      align-items: center;
      flex-wrap: wrap;

      li {
        margin-right: 2rem;

        p {
          font-size: 1.4rem;
          max-width: 150px;
        }

        svg {
          margin-right: 5px;
        }
      }
    }
  }
}

.map {
  z-index: 1;
  height: 100%;
  width: 100%;

  ::v-deep .leaflet-control-zoom {
    @include mq($until: m) {
      display: none;
    }
  }
}

::v-deep .marker-cluster-medium-test {
  background-color: rgba(242, 165, 87, 0.6);
}
::v-deep .marker-cluster-medium-test div {
  background-color: rgba(247, 165, 82, 0.6);
}
::v-deep .marker-cluster-small-test {
  background-color: rgba(248, 202, 92, 0.6);
}
::v-deep .marker-cluster-small-test div {
  background-color: rgba(247, 199, 82, 0.6);
}

::v-deep .marker-cluster-large-test {
  background-color: rgba(253, 156, 115, 0.6);
}
::v-deep .marker-cluster-large-test div {
  background-color: rgba(241, 128, 23, 0.6);
}
