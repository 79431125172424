
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        


























































































































.outages-dom-panel {
  // prettier-ignore
  @include fluid(padding-left, (xxs: 60px, xxl: 140px));
  // prettier-ignore
  @include fluid(padding-right, (xxs: 60px, xxl: 140px));
  // prettier-ignore
  @include fluid(padding-top, (xxs: 50px, xxl: 100px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 50px, xxl: 100px));

  .title {
    text-transform: none;
    @include fluid(
      font-size,
      (
        xxs: 32px,
        m: 42px,
      )
    );
  }

  .section {
    color: $c-main-primary;
  }

  .primary-color {
    color: $c-main-primary;
  }

  .bold {
    font-weight: bold;
  }

  .f-32 {
    font-size: 32px;
  }

  .no-margin {
    margin: 0;
  }

  .card {
    box-shadow: 0px 0px 24px rgba(24, 33, 77, 0.2);
    border-radius: 20px;
    padding: 3rem 3rem;

    .tel {
      display: flex;
      align-items: center;
      svg {
        fill: $c-main-primary;
      }
    }
  }

  ::v-deep .check-block {
    border-radius: 10px;
    padding: 20px;

    .content__copy .content__copy__title.h4 {
      font-family: $ff-alt;
      font-weight: normal;
      line-height: 1.5em;
    }
  }

  ::v-deep .is-active {
    border: 1px solid $c-orange;

    .content__copy .content__copy__title.h4 {
      color: $c-orange;
    }
  }

  ::v-deep h3 {
    font-weight: normal;
  }

  ::v-deep .fatline::after {
    background-color: $c-main-primary;
  }

  ::v-deep .switcher {
    border-radius: 5px;
    margin-top: 5px;
    padding: 3px 5px;
    margin-left: auto;
  }

  ::v-deep .switcher-contianer {
    @media screen and (min-width: 600px) {
      display: flex;
    }
    align-items: center;
  }
}
