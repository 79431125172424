
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





































































































.inputs {
  //box-shadow: $box-shadow;
  padding: 3.5rem 0;
  border-radius: 20px;
  margin-left: -10px;
  @include mq(xxs) {
    box-shadow: $box-shadow;
    margin-left: -10px;
    padding: 3.5rem col(1);
  }
  @include mq(xs) {
    margin-left: 0px;
  }
  @include mq(m) {
    border-radius: 0;
    box-shadow: none;
    padding: 0;
  }
}

.meter--light {
  // ::v-deep .input {
  //   letter-spacing: 0.5rem;
  //   @include mq(xs) {
  //     letter-spacing: 0.75rem;
  //   }
  //   @include mq(s) {
  //     letter-spacing: 1.2rem;
  //     padding-left: 0.5rem;
  //   }
  // }
}

.technician-passage-page {
  ::v-deep .btn {
    display: flex;
    width: 100%;
    @include mq(m) {
      display: inline-flex;
      width: auto;
    }
  }
}
