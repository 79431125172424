
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



















































































































































































































































































































































































































.cpt-text_rich + .cpt-text_rich {
  ::v-deep hr {
    display: block;
  }
}

.related_links {
  @include wrapper-mix;
}

.cpt-v_video,
.cpt-cookie_table,
.text_shortcodes {
  max-width: $content-width;
  width: 100%;
  padding-right: col(2, 24);
  padding-left: col(2, 24);

  &[class*='--no-padding'] {
    padding-right: 0;
    padding-left: 0;
  }

  @include mq(m) {
    //max-width: col(16, 24);
    padding-right: col(1, 24);
    padding-left: col(1, 24);
  }

  @include mq(xl) {
    //max-width: col(18, 24);
    padding-right: col(2, 24);
    padding-left: col(2, 24);
  }

  @include mq(wrapper) {
    margin-right: auto;
    margin-left: auto;
    padding-right: calc(#{$content-width} / 24 * 2);
    padding-left: calc(#{$content-width} / 24 * 2);
  }
}
.gap {
  column-gap: 9rem;
}
.contact-form-page {
  position: relative;

  .container-sticky {
    right: 4.1666666667%;

    @media only screen and (min-width: 85em) {
      right: 8.3333333%;
    }
  }

  ::v-deep {
    .main-title__title {
      // prettier-ignore
      @include fluid(font-size, (xxs: 28px, m: 48px));
    }

    .accordion {
      padding: 0 !important;
    }
  }
}

.contact-formulaire {
  width: 75%;
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
}

.contact-formulaire div.confirm-text {
  display: flex;
  color: $c-green;
  fill: $c-green;
}

.contact-formulaire span.text-gray {
  color: $c-gray-darker;
  display: contents;
}

.contact-formulaire div.confirm-follow {
  @include mq(m) {
    display: flex;
  }
}

.contact-formulaire h2.confirm {
  color: $c-main-primary;
}

.contact-formulaire {
  margin-bottom: 65px;
}

.contact-formulaire .fatline {
  font-size: 17px;
}

.contact-formulaire .fatline::after {
  background-color: #e84e0f;
}

.contact-formulaire div.panel-accordion {
  border-radius: 10px;
  margin-bottom: 25px;
}

.contact-formulaire div.panel-accordion div.panel-accordion-title:hover span {
  font-weight: normal;
}

.contact-formulaire
  div.panel-accordion
  div.panel-accordion-title.active
  .level-name {
  font-weight: normal;
}

.contact-formulaire div.panel-accordion div.panel-accordion-content {
  padding: 15px;
}

.contact-formulaire div.panel-accordion-content .level-list-item {
  margin-bottom: 5px;
  cursor: pointer;
}

.contact-formulaire div.panel-accordion-content .level-list-item a {
  color: #575756;
}

.contact-formulaire .confirm-button {
  margin-top: 20px;
  padding-top: 0;
  margin-bottom: 20px;
  padding-bottom: 0;
}

.form-footer {
  align-items: baseline;
}

.contact-formulaire .send-btn-container {
  margin-left: auto;
}
