
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





































































































































::v-deep {
  .datepicker .input-datepicker {
    border-bottom: none;
    padding-left: 3rem;
  }

  @media screen and (max-width: 750px) {
    .vdp-datepicker {
      text-align: right;
      padding-top: 1.5rem;
    }
  }
}
.text-gravity {
  background: left 5px center no-repeat;
  background-image: url('../../assets/svg/icons/calendar.svg');
  background-size: 24px 24px;
  height: 40px;
  border-radius: 10px;
  border: solid 1px #c4c4c4;
  padding: 5px;
  @media screen and (max-width: 750px) {
    padding-top: 20px;
    max-width: 100%;
    max-height: 5rem;
  }
}
