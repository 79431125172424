
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        









































































































.guidance-usages {
  h3 {
    padding-bottom: 10px;
    padding-right: 0px;
    border-bottom: 1px solid $c-main-primary;
  }

  h4 {
    font-weight: inherit;
  }
}

.guidance-usages-section {
  @include fluid(
    padding-right,
    (
      xs: 0px,
      m: 0px,
    )
  );

  .switcher--dual {
    float: right;
  }
  ::v-deep .content__copy__text.mt-xxs {
    margin-top: 0 !important;
  }
}
::v-deep .input-unit__data .input-unit__field {
  text-align: right;
  padding-right: 8px;
}
.input_total {
  ::v-deep .input-unit__label {
    font-weight: bold;
  }

  ::v-deep .input-unit__field {
    &:disabled {
      padding-left: 0em;
      color: $c-black;
      background: transparent;
    }
  }
}

.switcher-label {
  padding-right: 15px;
}
