
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

































































































































.edit__resume {
  justify-content: space-between;
}

h3 {
  padding-bottom: 1em;
}

.edit__resume__choice {
  display: flex;
  flex-flow: row wrap;
  height: fit-content;
  align-items: center;
  margin: 50px 0 0;
}

.bold {
  font-weight: 400;
}

.edit__resume__content {
  height: fit-content;
}

.edit__resume__content > span {
  font-size: 2rem;
  line-height: 150%;
  display: block;
}

.edit__resume__content > div {
  margin: 1em 0;
}

.edit__resume__content > div .meter__label {
  font-weight: 600;
  font-size: 2rem;
  display: block;
  line-height: 150%;
  color: $c-gray-darker;
}

.edit__resume__content .edit__resume__meter p.label-elec {
  color: #f7a600;
}

.edit__resume__content .edit__resume__meter p.label-gaz {
  color: #5dad98;
}

.edit__resume__content .edit__resume__meter p {
  font-size: 1.6rem;
  line-height: 150%;
  display: block;
  color: $c-gray-darker;
}

.edit__resume__content .row {
  justify-content: space-between;
  align-items: center;
}

.edit__resume__meter {
  border-bottom: 1px solid $c-gray;
  padding: 30px 0;
}

.orange {
  color: $c-main-primary;
}

.switcher {
  margin-left: auto;
}

.edit__resume__remove {
  fill: $c-main-primary;
}
