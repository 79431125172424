
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        




















































































































































































.check-block-container {
  @media screen and (max-width: 720px) {
    width: 100%;
    margin-bottom: 0.8em;
  }

  .check-block {
    margin-right: 1.5em;
    padding: 1em;
    margin-top: 1em;

    @media screen and (max-width: 720px) {
      margin-right: 0;
      width: 100%;
    }
  }

  ::v-deep .check-block.is-active {
    color: $c_white;
    background-color: $c_orange;
  }

  ::v-deep .check {
    margin-left: 1em;
  }
}
