
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

































































































































































































































.bold {
  font-weight: bold;
}

.types__item {
  &:not(:last-child) {
    margin-right: 1rem;
  }
}

.phone {
  display: flex;
  width: 100%;
  color: $c-gray;

  ::v-deep .input {
    width: 100%;
  }
}
