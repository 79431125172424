
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

































































































.info {
  width: 40%;
  @media screen and (max-width: 760px) {
    width: 5%;
  }
}

.sticky {
  border: 0.2rem solid #d8d8d8;
  //min-height: 35rem;
  border-radius: 20px;
  position: sticky;
  visibility: hidden;
  top: 110px;

  h3 {
    padding: 20px;
    width: 40%;
    display: inline-block;
  }

  .openMobile {
    width: 60%;
    padding: 23px;
    display: inline-block;
    text-align: right;
    color: $c-orange;
    cursor: pointer;
    .accordion-item__header__icon,
    .accordion-item__header__icon__close {
      fill: $c-main-primary;
      flex-shrink: 0;
      overflow: visible;
      width: 1.2rem;
      height: 1.2rem;
      margin-left: 1.5rem;
      margin-right: 1rem;
    }

    .accordion-item__header__icon__close {
      transform: rotate(-180deg);
    }
  }

  @media screen and (max-width: 760px) {
    position: fixed;
    top: 7.5rem;
    left: 10%;
    width: 80%;
    z-index: 98;
    background-color: $c-white;
  }
}

.visible {
  visibility: visible;
}
.info_content {
  display: flex;
  height: 5rem;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;

  div {
    display: flex;
    padding-right: 5px;
  }
}
.info_content-qty {
  color: #e84e0f;
}
.total {
  border-top: 0.2rem solid #d8d8d8;
  justify-content: space-between;
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  height: 10rem;
  align-items: center;
  margin-top: 2.5rem;
  color: $c-orange;
}
.border {
  border-top: 0.2rem solid #d8d8d8;
  border-bottom: 0.2rem solid #d8d8d8;
  padding-bottom: 2px;
  padding-top: 2px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  height: 5rem;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    padding-right: 5px;
  }
}
