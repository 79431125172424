
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        






















































svg {
  fill: $c-dark-green;
}
.success {
  color: $c-dark-green;
  display: flex;
  font-size: 3rem;
  font-weight: inherit;
  text-transform: inherit;
  font-family: proxima-nova, sans-serif;
  line-height: 1.1;
  margin-left: 5px;
}
.title {
  display: flex;
}
.error {
  color: red;
}
.content {
  margin-bottom: 50px;
}
.situation {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}
.situation-input {
  width: 45%;
  margin-bottom: 50px;
}
.situation-input-name {
  width: 45%;
}
.situation-container {
  width: 100%;
  h2 {
    margin-bottom: 20px;
  }
  h3 {
    margin-bottom: 20px;
  }
  h1 {
    color: $c-dark-green;
    display: flex;
    font-size: 3rem;
    font-weight: inherit;
    text-transform: inherit;
    font-family: proxima-nova, sans-serif;
    line-height: 1.1;
    margin-left: 5px;
  }
  h2 {
    color: #e84e0f;
    font-size: 20px;
    line-height: 1.1;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.myresa-btn {
  width: 250px;
  margin-left: 0 !important;
}
