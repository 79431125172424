
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        















































































































































.guidance-simulation {
  .switcher--dual {
    float: right;
  }
  ::v-deep .content__copy__text.mt-xxs {
    margin-top: 0 !important;
  }
}

.guidance-simulation-disclaimer {
  font-size: 14px;
}

.pr {
  padding-right: 10px;
}
