
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        














































































































































.vehicles-resume {
  font-weight: bold;
}

.vehicles-datas {
  max-width: 51%;

  @media screen and (max-width: 720px) {
    max-width: 100%;
  }

  ::v-deep .input__label {
    font-size: 1.8rem;
  }
}
