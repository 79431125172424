
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

























































































































































































.bold {
  font-weight: bold;
}

.check-block--small-title {
  margin-right: 20px;
}

.tva__confirm {
  color: $c-main-primary;
}

.check-block {
  border-radius: 10px;
  margin-right: 30px;
  padding: 20px;
  ::v-deep {
    .content__copy .content__copy__title.h4 {
      font-family: $ff-alt;
      font-size: 18px;
      font-weight: normal;
    }
  }
  &.is-active {
    border: 1px solid $c-orange;
    ::v-deep {
      .content__copy .content__copy__title.h4 {
        color: $c-orange;
      }
    }
  }
}

.checkbox.tva__confirm {
  margin-top: 30px;
  ::v-deep {
    .label {
      color: $c-orange;
      font-size: $body-text-m;
    }
    .label::before {
      border: 0.1rem solid $c-gray-darkest;
      height: 1.8rem;
      margin-top: -2px;
      width: 1.8rem;
    }
    .label::after {
      background-image: url('../../../../assets/svg/icons/check-orange.svg');
      left: 0.4rem;
      top: 0.2rem;
    }
  }
}
