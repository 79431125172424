
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



























.header {
  ::v-deep .content__title {
    color: $c-main-secondary;
    font-size: 35px;
    @include mq(m) {
      font-size: 55px;
    }
  }

  ::v-deep .slot {
    @include mq($until: m) {
      display: flex;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.breadcrumb + .m-xl {
  margin-top: 0;
}

hr {
  display: none;
  width: 100%;
  background: $c-gray-light;
}

.tip-icon {
  display: inline-flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  fill: $c-orange;
}
.tip__icon {
  margin-right: 10px;
  transform: translate(0px, 0px);
}
.tip-wording {
  color: $c-orange;
}
