
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





















.final__main {
  margin-right: 50px;
}

.final__confirm {
  color: $c-orange;
  font-family: $ff-alt;
}

.final__confirm__message {
  font-size: 22px;
}

.final__confirm__wrapper {
  display: flex;
  align-items: baseline;
}
