
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

















































.news {
  position: relative;
  width: 100%;

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $c-green;
    // border-top: 5px $c-main-primary solid;
  }

  &::before {
    max-width: calc(55rem - #{col(2)});
  }

  &::after {
    right: 100%;
    left: auto;
    width: 100vw;
  }
}

.news__title {
  // prettier-ignore
  @include fluid(font-size, (xxs: 24px, xxl: 36px));

  color: $c-white;

  @include mq($until: l) {
    margin-bottom: 3rem;
  }
}

::v-deep .news-slider-outer {
  @include mq($until: l) {
    margin-bottom: 3rem;
  }
}

.news-cards {
  display: flex;
  flex-direction: column-reverse;

  @include mq(l) {
    flex-direction: row;
    align-items: flex-end;
  }
}

.news-slider__nav {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-top: 2rem;

  @include mq(l) {
    flex-direction: column;
    align-items: flex-start;
    width: col(3, 22);
    margin-top: 0;
    margin-right: col(2, 22);
  }

  @include mq(xl) {
    width: col(3);
    margin-right: col(2);
  }
}

.news-cards__nav__controls {
  @include mq(l) {
    margin-top: 4rem;
  }
}

.news-cards__nav__item,
[class*='news-cards__nav__item--'] {
  // prettier-ignore
  @include fluid(width, (xxs: 40px, xxl: 60px));
  // prettier-ignore
  @include fluid(height, (xxs: 40px, xxl: 60px));

  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: $c-gray-darkest;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.2s;

  .icon {
    fill: $c-white;
    transition: transform 0.2s;
  }

  &[class*='--prev'] {
    margin-right: 1rem;

    .icon {
      transform: rotate(180deg);
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &:hover:not(:disabled) {
    .icon {
      transform: translateX(0.5rem);
    }

    &[class*='--prev'] {
      .icon {
        transform: translateX(-0.5rem) rotate(180deg);
      }
    }
  }
}

.news-cards__nav__pagination {
  @extend %fw-thin;
  // prettier-ignore
  @include fluid(font-size, (xxs: 18px, xxl: 24px));

  display: flex;
  align-items: flex-end;
  margin-right: 7rem;
  color: $c-white;
  line-height: 1;

  @include mq(l) {
    display: none;
    margin-right: 0;
  }
}

.news-cards__nav__pagination__current {
  // prettier-ignore
  @include fluid(font-size, (xxs: 48px, xxl: 96px));

  margin-right: 1rem;
  line-height: 0.75;
}

.news-cards__cards {
  display: flex;
}

.news-cards__cards__item {
  flex-shrink: 0;
  width: 30rem;
  padding-right: 2rem;
}
