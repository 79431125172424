
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





























































































.mobilite-contact__list {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobilite-contact__list__item {
  margin-right: 2rem;

  a {
    display: flex;
    align-items: center;
    color: $c-default;
    text-decoration: none;

    svg {
      margin-right: 1rem;
    }

    span {
      font-size: 1.4rem;
    }
  }
}

.mobilite-contact__info {
  font-size: 1.3rem;
}

.orange-text {
  color: $c-orange;
}

::v-deep .block-content-image {
  border-radius: 30px;
  margin-right: 30px;
  width: 296px;
  padding: 20px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.28);

  div.img-block .title {
    color: $c-orange;
    font-weight: bolder;
    font-size: 1.3em;
    display: flex;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    flex-basis: 100%;
    padding-left: 45px;
  }
}
