
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        




















































































































.input {
  position: relative;
  color: $c-orange;
  border-bottom: 1px solid $c-gray-lighter;

  &::-ms-clear {
    display: none;
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
  }
}

.input__label {
  @media screen and (min-width: 720px) {
    height: 26.3px;
  }

  display: block;
}

.input__field {
  width: 100%;
  padding: 0.5rem 0 0.3rem;
  font-size: 1.8rem;
  background: transparent;
  border: 0;

  &::placeholder {
    @extend %fw-light;

    color: $c-gray-light;
  }

  &:disabled {
    padding-left: 0.5em;
    color: darken($c-gray-light, 10%);
    background: lighten($c-gray-light, 10%);

    &:hover {
      cursor: not-allowed;
    }
  }
}

.text-small {
  font-size: small;
}
