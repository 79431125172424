
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        


























.gaz__item {
  color: #5dad98;
  font-weight: bold;
}

.electricity__item {
  color: #f7a600;
  font-weight: bold;
}

.block {
  text-align: center;
  padding: 20px;
  margin-right: 20px;
}

.block__title {
  align-items: center;
  justify-content: center;
  display: flex;
}

.block__text {
  text-align: left;
}
