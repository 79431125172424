
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        


























































































































































































































































































































































































































































































































































.step-header {
  display: flex;
  align-items: center;

  .step-logo {
    margin-left: 20em;
  }

  @media screen and (max-width: 720px) {
    display: block;

    .step-logo {
      margin-top: 15px;
      margin-left: unset;
    }
  }
}

.switcher-container {
  display: flex;

  @media screen and (max-width: 720px) {
    display: block;
  }
}

.switcher {
  margin-left: 11em;

  ::v-deep {
    margin-top: -0.7em;

    @media screen and (max-width: 720px) {
      margin-left: 0 !important;
      margin-top: 1em;
    }
  }
}

.select-vehicles-datas {
  .select {
    width: 60%;

    @media screen and (max-width: 720px) {
      width: 100%;
    }
  }

  ::v-deep.headline {
    font-size: 1.8rem;
  }

  ::v-deep svg {
    fill: $c_orange;
  }

  ::v-deep .vs__selected-options {
    padding: 2px 2px 0 5px;

    .vs__selected {
      padding: 0 0 0 5px;
    }
  }
}

.check-block-container {
  display: flex;

  @media screen and (max-width: 720px) {
    display: block;
    width: 100%;
    margin-top: 1em;
    margin-bottom: 2em;
  }

  ::v-deep .check-block {
    margin-right: 3em;
    padding-right: 3em;

    @media screen and (max-width: 720px) {
      margin-right: 0;
      margin-top: 1em;
      width: 100%;
    }
  }

  ::v-deep .check-block.is-active {
    color: $c_white;
    background-color: $c_orange;
  }

  ::v-deep .check {
    margin-left: 2em;
  }
}

.encode-vehicles-datas {
  max-width: 60%;

  @media screen and (max-width: 720px) {
    max-width: 100%;
  }

  ::v-deep .input__label {
    font-size: 1.8rem;
  }
}
