
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        















































































































































































































































.align__text {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.align__checkbox {
  display: ruby;
  justify-content: space-between;
  align-content: center;
  cursor: pointer;
  font-size: 15px;
}

.request__info {
  font-size: 15x;
}

span.fatline {
  font-size: 0;
  margin: 50px 0 30px;
}

h4.fatline div.h4.fatline {
  margin-top: 10px;
}

.note-date {
  font-size: 14px;
  padding-top: 3px;
}

.request__star {
  margin-top: 30px;
  font-size: 14px;
  color: $c-gray-darkest;
}

.h4 {
  font-family: $ff-alt;
}

.check-block {
  border-radius: 10px;
  padding: 20px;
  ::v-deep {
    .content__copy__title.h4 {
      font-family: $ff-alt;
      font-size: 18px;
      font-weight: normal;
    }
  }
  &.is-active {
    border: 1px solid $c-orange;
    ::v-deep {
      .content__copy__title.h4 {
        color: $c-orange;
      }
    }
  }
}

.switcher-row span {
  @media screen and (max-width: 960px) {
    width: 100%;
  }
}

.connection-page .switcher {
  ::v-deep {
    .slider {
      border-radius: 3px;
    }
  }
}
.connection-page .switcher {
  ::v-deep {
    margin-left: 2em;
    margin-top: -0.7em;

    .list__item {
      padding-left: 25px;
      padding-right: 25px;
    }

    @media screen and (max-width: 720px) {
      margin-top: 1em;
      margin-left: 0 !important;
    }
  }
}

.checkbox {
  ::v-deep {
    .label {
      font-size: 15px;
      padding-left: 2.9rem;
    }
    .label::before {
      border: 0.1rem solid $c-gray-darkest;
      height: 1.8rem;
      margin-top: -2px;
      width: 1.8rem;
    }
    .label::after {
      left: 0.4rem;
    }
  }
}
.textarea {
  ::v-deep {
    .headline {
      color: $c-gray-darkest;
      font-size: $body-text-m;
    }
  }
}
.checkbox.tva__confirm {
  margin-top: 30px;
  ::v-deep {
    .label {
      color: $c-orange;
      font-size: $body-text-m;
    }
    .label::before {
      border: 0.1rem solid $c-gray-darkest;
      height: 1.8rem;
      margin-top: -2px;
      width: 1.8rem;
    }
    .label::after {
      background-image: url('../../../assets/svg/icons/check-orange.svg');
      left: 0.4rem;
      top: 0.2rem;
    }
  }
}
.bold {
  font-weight: bold;
}

.phone {
  display: flex;
  width: 100%;
  color: $c-gray;

  ::v-deep .input {
    width: 100%;
  }
}
.date-souhaitee-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  > * {
    margin: 0 1em 0 0;

    @media screen and (max-width: 720px) {
      margin: 0;
    }
  }

  .date-souhaitee-label {
    color: #ff4b00;
  }
}

.form__gdpr {
  ::v-deep .action__label {
    text-decoration: underline;
  }
}
