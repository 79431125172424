
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

















.counter {
  @extend %border-radius;

  display: inline-flex;
  background: $c-white;
}

.value {
  @extend %fw-bold;
  // prettier-ignore
  @include fluid(width, (xxs: 40px, m: 60px));
  // prettier-ignore
  @include fluid(font-size, (xxs: 15px, m: 18px));

  display: flex;
  justify-content: center;
  align-items: center;
  border: solid $c-gray;
  border-width: 1px 0;
}

.btn {
  // prettier-ignore
  @include fluid(width, (xxs: 40px, m: 50px));
  // prettier-ignore
  @include fluid(height, (xxs: 40px, m: 60px));
  // prettier-ignore
  @include fluid(font-size, (xxs: 20px, m: 30px));

  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  color: $c-gray;
  font-size: 3rem;
  line-height: 1;
  background: transparent;
  border: 1px solid $c-gray;
  cursor: pointer;
  transition: all 0.3s;

  &:disabled {
    cursor: default;
  }

  &::after,
  &::before {
    @include center-xy;

    content: '';
    width: 1.4rem;
    height: 2px;
    background: $c-gray;
    transition: background-color 0.3s;
  }

  &::after {
    transform: translate(-50%, -50%) rotate(90deg);
  }

  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    &::after {
      display: none;
    }
  }

  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &:hover:not(:disabled) {
    background-color: $c-orange;
    border-color: $c-orange;

    &::after,
    &::before {
      background-color: $c-white;
    }
  }
}
