
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

















































































































.outages-view {
  position: relative;

  @include mq($until: m) {
    position: relative;
  }

  @include mq(m) {
    border-top: 1px solid $c-gray;
  }
}

.margin-neg {
  @include mq($until: m) {
    margin-bottom: -10rem;
  }
}

.outages-view-header {
  @include mq($until: m) {
    position: relative;
    z-index: 2;
  }
}

.outages-view-address {
  margin-bottom: 0;

  @include mq($until: m) {
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.15);
    padding: 3rem;
    border-radius: 20px;
    background-color: white;
    z-index: 2;
  }
}

.outages-view-element {
  justify-content: space-between;

  @include mq($until: m) {
    margin-top: 3rem;
    align-items: center;
  }
}

.outages-view-locate {
  @include mq(m) {
    display: none;
  }
}

.outages-view-filters {
  height: fit-content;
  max-width: fit-content;
}

.element-marging-top {
  @include mq(m) {
    margin-top: 25px;
  }

  @include mq($until: m) {
    display: contents;
  }
}

.outages-view-switcher {
  height: fit-content;
  max-width: fit-content;

  border-radius: 5px;
  padding: 3px 5px;
  margin-top: 0 !important;
  margin-left: 0 !important;
}

.outages-view-map {
  @include mq($until: m) {
    position: relative;
    bottom: 120px;
  }

  @include mq(m) {
    padding-left: col(1, 24);
    padding-right: col(1, 24);
  }

  @include mq(l) {
    padding-right: 0;
  }

  @include mq(xl) {
    padding-left: col(2, 24);
    margin-right: 0;
  }
}

.map-title {
  @include mq($until: m) {
    display: none;
  }
}

.map-subtitle {
  display: flex;
  justify-content: center;
  color: #818181;
  font-size: 1.4rem;
}

.error-checkaddress {
  display: flex;

  .error-msg {
    fill: $c-red;
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 0.5rem;
  }
}
