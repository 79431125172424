
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        













.m__block {
  margin-right: 2%;
}

.multi__blocks {
  width: 100%;
  display: flex;
  justify-content: center;
}
