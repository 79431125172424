
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



























































































































































































































































































.captcha-label {
  font-size: 15px;
}
.request__info {
  font-size: 15px;
}

.captcha-gdpr {
  padding: 5rem 0rem;
  border-top: 1px solid #d3d4d8;
  border-bottom: 1px solid #d3d4d8;
}

.buttons-form {
  .button {
    border-radius: 20px !important;
  }

  .button-confirm {
    margin-left: auto;
  }
}

.phone {
  display: flex;
  width: 100%;
  color: $c-gray;

  ::v-deep .input {
    width: 100%;
  }
}

.phone__flag {
  flex-shrink: 0;
  width: 2rem;
  margin-right: 0.5rem;
}

.country-codes {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 1rem;
  padding: 0.5rem;
  border-bottom: 1px solid $c-gray-lighter;

  ::v-deep .placeholder {
    justify-content: flex-end;
  }

  ::v-deep .options {
    width: 9rem;
  }
}
