
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        








































































.press {
  overflow: hidden;
}

.header__title {
  @include mq(s) {
    order: 1;
  }
}

.header__slider {
  @include mq(s) {
    order: 3;
  }
}

.header__tip {
  @include mq(s) {
    order: 2;
  }
}

.tip-contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 28rem;
  border-bottom: 1px solid $c-gray-light;

  @include mq(xl) {
    margin-top: 0;
  }
}

.tip-contact__icon {
  width: 1.8rem;
  height: 1.8rem;
  margin-bottom: 1rem;
  fill: $c-orange;
}

.tip-contact__title {
  @extend %fw-medium;

  margin-bottom: 1rem;
  font-size: 1.8rem;
}

.tip-contact__text {
  font-size: 1.5rem;
}
