
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        
































.header {
  .content__title {
    color: $c-yellow;
    font-size: 35px;
    @include mq(m) {
      font-size: 55px;
    }
  }

  ::v-deep .slot {
    @include mq($until: m) {
      display: flex;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.breadcrumb + .m-xl {
  margin-top: 0;
}
