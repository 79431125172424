
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        


























.outages-report-content {
  position: relative;
}

.outage-report {
  ::v-deep .check-block {
    border-radius: 10px;
    margin-right: 30px;
    padding: 20px;

    .content__copy .content__copy__title.h4 {
      font-family: $ff-alt;
      font-weight: normal;
      line-height: 1.5em;
    }
  }

  ::v-deep .is-active {
    border: 1px solid $c-orange;

    .content__copy .content__copy__title.h4 {
      color: $c-orange;
    }
  }

  ::v-deep h3 {
    font-weight: normal;
  }

  ::v-deep .fatline::after {
    background-color: $c-main-primary;
  }

  ::v-deep .switcher {
    border-radius: 5px;
    margin-top: 5px;
    padding: 3px 5px;
    margin-left: auto;
  }

  ::v-deep .switcher-contianer {
    @media screen and (min-width: 600px) {
      display: flex;
    }
    align-items: center;
  }
}

.header {
  ::v-deep .content__title {
    color: $c-main-secondary;
    font-size: 35px;
    @include mq(m) {
      font-size: 55px;
    }
  }

  ::v-deep .slot {
    @include mq($until: m) {
      display: flex;
      margin-left: auto;
      margin-right: auto;
    }
  }

  ::v-deep .button {
    border-radius: 20px;
    padding: 1.5rem 2.5rem !important;
  }
}

.flexibles {
  display: inline-block;
}
