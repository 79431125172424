
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        










































.filters-title {
  display: flex;
  align-items: center;
}

.icon-close {
  cursor: pointer;
  margin-left: auto;
  fill: $c-main-primary;
  transform: rotate(180deg);
}

.filters-panel {
  p {
    font-size: 18px !important;
  }

  @media screen and (min-width: 960px) {
    position: absolute;
    z-index: 9;
    background-color: white;
    border: 2px solid #f1f0f0;
    width: 100%;
    padding: 2rem 2rem;
  }
}

.section-label {
  color: $c-main-primary;
}

.filters-button {
  @media screen and (min-width: 960px) {
    width: 100%;
  }

  padding: 1.5rem 2.5rem;
  border-radius: 20px !important;
}

.filters-section {
  ul {
    cursor: pointer;
    padding: 0 3rem;
    color: $c-main-primary;
  }

  ul.filters-bullet {
    list-style: circle;
  }

  li.active {
    list-style: disc;
  }

  .filters-bullet p {
    color: #575756;
    font-size: 1.8rem;
  }

  p.active {
    color: #575756;
    font-size: 1.8rem;
    font-weight: bold;
    color: $c-main-primary;
  }
}
