
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        






































































.perm-capacity-map-panel {
  // prettier-ignore
  @include fluid(padding-left, (xxs: 60px, xxl: 100px));
  // prettier-ignore
  @include fluid(padding-right, (xxs: 60px, xxl: 100px));
  // prettier-ignore
  @include fluid(padding-top, (xxs: 50px, xxl: 100px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 50px, xxl: 100px));

  .title {
    text-transform: none;
    @include fluid(
      font-size,
      (
        xxs: 32px,
        m: 42px,
      )
    );
  }

  .content {
    display: flex;
    flex-direction: column;

    .data--cell {
      margin-left: auto;
    }
  }

  .panel--legend {
    border-top: 1px dashed $c-gray-separator;

    p {
      font-size: 1.4rem;
    }
  }
}
