
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        


























































































.orange {
  color: $c-main-primary;
}
.bad__ean {
  color: $c-main-primary;
  font-weight: 500;
}

.submit {
  @include loading($c-orange);
}

.form {
  margin-bottom: 11rem;

  @include mq(m) {
    margin-bottom: 7rem;
  }
}

.error {
  margin-bottom: 2rem;
}

.nav {
  text-align: right;
}

.meter--light {
  // ::v-deep .input {
  //   letter-spacing: 0.5rem;
  //   @include mq(xs) {
  //     letter-spacing: 0.75rem;
  //   }
  //   @include mq(s) {
  //     letter-spacing: 1.2rem;
  //     padding-left: 0.5rem;
  //   }
  // }
}

.switcher {
  ::v-deep {
    margin-top: -0.7em;

    @media screen and (max-width: 720px) {
      margin-left: 0 !important;
      margin-top: 1em;
    }
  }
}

.edit__setup___address p.address {
  text-transform: uppercase;
}
.grey-txt {
  color: $c-gray-darker;
}
.headline {
  color: $c-gray-darker;
}
.bold {
  font-weight: bold;
}
