
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        
































.subpage {
  position: relative;
  /*
  ::v-deep .breadcrumb {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }*/
}

/*
.flexible {
  ::v-deep div:first-child {
    margin-top: 0;
  }
}
*/
.breadcrumb + .m-xl {
  margin-top: 0;
}

.cpt-text_rich + .cpt-text_rich {
  ::v-deep hr {
    display: block;
  }
}

.related_links {
  padding-right: col(2, 24);
  padding-left: col(2, 24);
}

.cpt-v_video,
.cpt-cookie_table,
.text_shortcodes {
  max-width: $content-width;
  width: 100%;
  padding-right: col(2, 24);
  padding-left: col(2, 24);

  &[class*='--no-padding'] {
    padding-right: 0;
    padding-left: 0;
  }

  @include mq(m) {
    //max-width: col(16, 24);
    padding-right: col(1, 24);
    padding-left: col(1, 24);
  }

  @include mq(xl) {
    //max-width: col(18, 24);
    padding-right: col(2, 24);
    padding-left: col(2, 24);
  }

  @include mq(wrapper) {
    margin-right: auto;
    margin-left: auto;
    padding-right: calc(#{$content-width} / 24 * 2);
    padding-left: calc(#{$content-width} / 24 * 2);
  }
}

.video {
  ::v-deep .video__content__title {
    flex-direction: column;
  }

  ::v-deep .icon {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
