
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        














































.header {
  display: inline;
}

.header_svg {
  opacity: 0.3;
  fill: #e84e0f;
}

.icons__energy {
  justify-content: center;
  align-items: center;
  display: inline-flex;

  svg {
    margin-right: 10px;
  }
}

mark {
  background: none;
}

.search-header {
  position: relative;
  padding-bottom: 2em;

  .container-sticky {
    right: 4.1666666667%;

    @media only screen and (min-width: 85em) {
      right: 8.3333333%;
    }
  }

  .search-header-title {
    position: relative;
    padding-left: 90px;

    @media screen and (max-width: 32em) {
      padding-left: 50px;
    }
  }

  h1 {
    @media screen and (max-width: 32em) {
      font-size: 32px;
    }
  }

  .header_svg {
    position: absolute;
    left: 0;

    @media screen and (max-width: 32em) {
      width: 30px;
      height: 30px;
    }
  }
}
