
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        




























































.quantity {
  position: relative;
}

.container {
  margin: 30px 0px;

  @include mq(l) {
    position: absolute;
    height: 90%;
    top: 150px;
    right: 0;
    margin: 0;
  }
}

.guidance__tip {
  @include mq(l) {
    //margin-left: 20px;
    border: 4px solid $c-yellow;
    border-radius: 20px;
    padding: 3rem 3rem;
    position: -webkit-sticky;
    position: sticky;
    top: 150px;
  }

  .guidance__tip__button {
    @include mq($until: l) {
      width: 100%;
    }
  }

  ::v-deep .tip {
    padding: 0px;

    @include mq(l) {
      max-width: 28rem;
    }
  }

  ::v-deep .tip__text {
    @include mq($until: l) {
      color: $c-main-primary;
    }
  }

  ::v-deep .tip__icon {
    width: 2.4rem;
    height: 2.4rem;

    @include mq($until: l) {
      display: none;
    }
  }
}

.align__counter {
  margin-top: auto;
  margin-bottom: auto;
}

.counter {
  ::v-deep {
    @media screen and (max-width: 720px) {
      margin: 1em 0 0 0;
    }

    .btn:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      &:before {
        background-color: $c-orange;
      }
      &:hover {
        &:before {
          background-color: white;
        }
      }
    }
  }
}
.counter {
  ::v-deep {
    @media screen and (max-width: 720px) {
      margin: 1em 0 0 0;
    }

    .btn:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      &:after,
      &:before {
        background-color: $c-orange;
      }
      &:hover {
        &:after,
        &:before {
          background-color: white;
        }
      }
    }
  }
}

.meter-label {
  font-family: $ff-default;
  font-size: 2.5rem;
  color: $c-main-primary;
}
