
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        




































































.margin {
  margin-top: 3rem;
}
.check-block-space {
  margin-right: 2rem;
}
.edit__smart__help {
  margin-left: 1%;
}
.edit__smart__star {
  color: $c-gray;
}
.bold {
  font-weight: bold;
}
.switcher {
  display: flex;
  justify-content: space-between;
  align-content: center;

  @media screen and (max-width: 720px) {
    flex-direction: column;
    align-content: flex-start;
  }
}
.edit__smart__img {
  align-items: center;
  display: flex;
  margin-top: 10px;
  flex-direction: row;
}
.check-block {
  border-radius: 10px;
  padding: 20px;
  ::v-deep {
    .content__copy .content__copy__title.h4 {
      font-family: $ff-alt;
      font-size: 18px;
      font-weight: normal;
      line-height: 1.5em;
    }
  }
  &.is-active {
    border: 1px solid $c-orange;
    ::v-deep {
      .content__copy .content__copy__title.h4 {
        color: $c-orange;
      }
    }
  }
}

.row__switcher {
  display: flex;
  align-content: center;

  @media screen and (max-width: 960px) {
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
  }
}

.switcher {
  ::v-deep {
    display: inline-block;

    @media screen and (max-width: 720px) {
      margin-left: 0 !important;
      margin-top: 1em;
    }
  }
}
