
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        












































































































.final__main {
  margin-right: 50px;
}

.final__confirm__wrapper {
  display: flex;
  align-items: baseline;
}

.check__svg {
  fill: $c-orange;
  margin-right: 15px;
}

.final__confirm {
  color: $c-orange;
  font-family: $ff-alt;
}

.final__confirm__message {
  font-size: 22px;
}

.final__gaz__promo {
  display: flex;
  padding: 10px 10px;
}

.final__gaz__text {
  margin: auto;
}

.final__gaz__img {
  margin-right: 50px;
  max-height: 300px;
  max-width: 300px;
}
.primary-color {
  color: $c-main-primary;
}
.bold {
  font-weight: 500;
  font-size: 22px;
}

.final_myresa {
  font-weight: bold;
  font-size: 22px;
  font-family: $ff-default;
  color: $c-main-primary;
}

.final__myresa__signin {
  display: flex;
  border: 1px solid $c-main-primary;
  border-radius: 20px;
  padding: 50px 50px;
}

.final__myresa__text {
  margin-right: 20%;
}

.final__myresa__signin__button {
  border-radius: 20px;
  margin-left: auto;
  padding: 1.5rem 2.2rem;
}

.timeline--steps {
  padding: 0;
  margin: 0 0 0 20px;
  list-style: none;
  position: relative;
}

.timeline--step {
  padding-bottom: 2vh;
  text-align: right;
  width: 95%;
  p.address {
    margin-top: 0;
    text-transform: uppercase;
  }
  &:before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0%;
    transform: translateX(-50%);
    border: 2px #b1b1b1;
    border-style: none dotted none none;
  }
}

.timeline--step.tva:before {
  border: 0px dotted transparent !important;
  border: none;
  border-style: none none none none;
  content: unset;
  display: none;
  height: 0px;
  position: relative;
  transform: unset;
  width: 0px;
}

.timeline--check {
  position: absolute;
  left: 0%;
  transform: translateX(-50%);
  color: white;
  font-size: 2rem;
  display: block;
  width: 34px;
  height: 34px;
  background: black;
  text-align: center;
  border-radius: 100px;
  font-family: Martel, sans-serif;
  z-index: 1;
}

.timeline--notcheck {
  position: absolute;
  left: 0%;
  transform: translateX(-50%);
  color: white;
  font-size: 2rem;
  display: block;
  width: 20px;
  height: 20px;
  background: $c-gray;
  text-align: center;
  border-radius: 100px;
  font-family: Martel, sans-serif;
  z-index: 1;
}

.timeline--step h5 {
  color: $c-gray;
  display: inline-flex;
  font-family: $ff-alt;
  font-size: 18px;
  font-weight: 300;
  line-height: 35px;
  margin: 0;
}

.timeline--step p {
  margin: 0em 0 0.8em 0;
}

.timeline--step.right .timeline--check {
  background: #ff4b00 url('../../../../assets/images/icons/orange-check.svg')
    center no-repeat;
}

.timeline--step.right {
  margin-left: 30px;
  text-align: left;
}

@media screen and (max-width: 760px) {
  .timeline--step {
    width: 90%;
  }
  .timeline--header {
    min-width: 0;
    width: 100%;
  }
  .timeline--header h2 {
    font-size: 3rem;
  }
}

.timeline--delay {
  background-image: url('../../../../assets/svg/icons/clock.svg');
  background-position: top 3px left;
  background-repeat: no-repeat;
  padding-left: 25px;
  font-size: 1.5rem;
  color: grey;
  display: flex;
  align-items: center;
}

.loader {
  .loading {
    margin: 50px auto 20px auto;
    height: 250px;
  }

  span {
    display: table;
    margin: 0px auto;
  }
}
