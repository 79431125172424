
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        


































.space__check-block {
  margin-right: 30px;
}

strong {
  font-weight: bold;
}

.icons-warning {
  margin-right: 10px;
  fill: $c-main-primary;
  opacity: 0.3;
}

.label {
  display: flex;
  align-items: center;
  color: $c-main-primary;
}

.documents__wrapper {
  border: 1px solid $c-main-primary;
  border-radius: 20px;
}

.documents__list {
  list-style-type: disc;
  margin: 20px 50px;
}

.document {
  color: $c-main-primary;
  font-size: 16px;
}

.check-block {
  border-radius: 10px;
  padding: 30px 30px;
  ::v-deep {
    .content__copy .content__copy__title.h4 {
      font-family: $ff-alt;
      font-size: 24px;
      font-weight: normal;
    }
  }
  &.is-active {
    border: 1px solid $c-orange;
    ::v-deep {
      .content__copy .content__copy__title.h4 {
        color: $c-orange;
      }
    }
  }
}
