
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        




















.outages-filters {
  position: relative;
}

.filters-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  @include mq(m) {
    color: $c-white;
    background-color: $c-main-primary;
    height: 10rem;
    width: 25rem;
    padding-left: 3rem;
  }
}

.icon-filter {
  margin-right: 1.2rem;
  @include mq(m) {
    fill: $c-white;
  }
}
