
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        






























































































































































.span-elec,
.span-gaz {
  width: 50px;
  padding-left: 30px;
  margin-right: 20px;
  background: left center url('../../../assets/svg/icons/electricityflood.svg')
    no-repeat;
  background-size: contain;
  color: #f7a600;
}

.span-gaz {
  background: left center url('../../../assets/svg/icons/gazflood.svg')
    no-repeat;
  color: #5dad98;
}

.loader {
  display: flex;
  justify-content: center;
  min-height: 20rem;
}

.meters__item {
  border-bottom: 1px solid $c-gray-lighter;
}

.meters__item__info {
  @include mq($until: xs) {
    margin-bottom: 1rem;
  }
}

.meters__item__name {
  color: $c-gray;
}

.meter__item__id {
  color: $c-gray;
}

.meters__item__btn {
  display: inline-flex;
  align-items: center;
  background: transparent;
  border: 0;
  cursor: pointer;

  svg {
    fill: $c-orange;
    margin-right: 0.5rem;
  }
}

.meters__item__btn__label {
  // prettier-ignore
  @include fluid(font-size, (xxs: 13px, m: 15px));

  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.meters__item__actions {
  display: flex;
  margin-top: 0.5em;

  @media screen and (min-width: 720px) {
    text-align: right;
    justify-content: flex-end;
    margin-top: 0;
  }
}

.meters__item__price {
  color: $c-orange;
  font-size: 24px;
  margin-top: 0.5em;

  @media screen and (min-width: 720px) {
    text-align: right;
    margin-top: 0;
  }
}

.meters__items__total {
  margin-left: 90%;
}

.title {
  border-bottom: 3px solid $c-black;
}

.price-label {
  @extend %fw-bold;

  text-transform: uppercase;
}

.info__wrapper {
  padding: 20px 30px;
  background-color: #fafafc;
  display: flex;

  @media screen and (max-width: 720px) {
    padding: 20px 15px;
    display: block;
  }
}

.info__svg {
  fill: $c-orange;
  width: 40px;
  height: 30px;
  margin-right: 20px;

  @media screen and (max-width: 720px) {
    width: 20px;
  }
}

.info__text {
  color: $c-gray-dark;
  font-size: 14px;
}
