
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        























































































































.files {
  font-size: 16px;
  list-style-type: disc;
  margin-left: 30px;
}

.info__wrapper {
  padding: 20px 30px;
  background-color: #fafafc;
  display: flex;
}

.info__svg {
  fill: $c-gray;
  width: 100px;
  height: 100px;
  margin-right: 20px;
}

.info__text {
  font-size: 14px;
  color: #787878;
}

.files-types {
  font-size: $body-text-m;
  strong {
    color: $c-orange;
    font-weight: normal;
  }
}
.connection-documents {
  ::v-deep {
    .vue-file-agent.file-input-wrapper {
      box-shadow: 0px 2px 2px 0px #00000026;
    }
    [helperclass='active-sorting-item'] {
      text-align: left;
      width: 100%;
      > div {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        /*width: 400px;*/
        width: 100%;
        padding-left: 15px;
        .file-name {
          font-weight: normal !important;
          margin-top: 5px;
          margin-left: 0;
          padding-left: 0;
        }
      }
    }
    .file-delete {
      width: auto;
      svg {
        background-color: #d3d4d8;
        height: 30px !important;
        width: 30px !important;
      }
    }
  }
  .file-progress.file-progress-done {
    width: 0px !important;
  }
  .info__wrapper {
    padding: 20px 40px 20px 10px;
    .info__svg {
      fill: #ff4b00;
      height: 40px;
      width: 40px;
      margin: 0 20px;
    }
    .info__text {
      font-size: 16px;
    }

    @media screen and (max-width: 720px) {
      padding: 20px 20px 20px 0;
    }
  }
}
