
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





















































































































































































































































































































































































.date {
  margin-bottom: 20px;
}
.private {
  margin-right: 5px;
}
span {
  margin-bottom: 20px;
  margin-top: 20px;
}
span.headline.col-xxxs-20.bidirectionnelle {
  margin-bottom: 0;
}
.connection-documents {
  ::v-deep {
    .vue-file-agent.file-input-wrapper {
      box-shadow: 0px 2px 2px 0px #00000026;
      background-color: white;
      border-radius: 10px;
    }
    .upload {
      width: 50%;
      padding-bottom: 20px;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
    [helperclass='active-sorting-item'] {
      text-align: left;
      width: 100%;
      > div {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        width: 100%;
        padding-left: 15px;
        .file-name {
          font-weight: normal !important;
          margin-top: 5px;
          margin-left: 0;
          padding-left: 0;
        }
      }
    }
    .serial {
      margin-bottom: 10px;
      margin-top: 10px;
      background-color: #fefbfa;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 20px;
      border-radius: 10px;
    }
    .my-commune-address .input-unit__icons {
      position: relative;
      top: 3px;
      left: 5px;
      fill: #e84e0f;
    }
    .communeEan {
      fill: #e84e0f;
      cursor: pointer;
    }
    .file-delete {
      width: auto;
      svg {
        fill: #e84e0f;
        background-color: #d3d4d8;
        height: 30px !important;
        width: 30px !important;
      }
    }

    .theme-list .vue-file-agent .file-preview-wrapper .file-preview {
      width: fit-content;
    }
  }
  .file-progress.file-progress-done {
    width: 0 !important;
  }
  .info__wrapper {
    padding: 20px 40px 20px 10px;
    .info__svg {
      fill: #ff4b00;
      height: 40px;
      width: 40px;
      margin: 0 20px;
    }
    .info__text {
      font-size: 16px;
    }

    @media screen and (max-width: 720px) {
      padding: 20px 20px 20px 0;
    }
  }
}
.flex {
  display: flex;
  align-items: center;

  @media only screen and (max-width: 960px) {
    display: block;
  }
}
.socketType {
  display: flex;
  align-items: flex-start;
  margin-top: 5px;
  justify-content: space-between;
  margin-bottom: 20px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    div {
      margin-top: 10px;
    }
  }
}
.socketType.bidirectionnelle div {
  margin-top: 20px;
}
.phone {
  display: flex;
  width: 100%;
  color: $c-gray;

  ::v-deep .input {
    width: 100%;
  }
}
.checkBlock-container {
  margin-top: 2.5rem;
  ::v-deep .label {
    font-weight: normal;
  }
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
}
.success {
  color: $c-green;
}
.error {
  color: $c-red;
}
.content {
  margin-bottom: 50px;
}
.situation {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
}
.situation-input {
  width: 45%;
  margin-top: 25px;
  margin-bottom: 20px;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}
.installer-input {
  width: 45%;
  margin-bottom: 20px;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}
.situation-input-name {
  width: 45%;
}
.situation-container {
  @media only screen and (min-width: 600px) {
    margin-right: 40%;
  }
  width: 100%;
  h3 {
    margin-bottom: 20px;
  }
  h1 {
    font-size: 1.3em;
    line-height: 1.1;
    margin-bottom: 2.5rem;
  }
  h2 {
    color: $c-black;
    font-size: 1.11em;
    line-height: 1.1;
    font-weight: bold;
  }
}

::v-deep input::placeholder {
  @extend %fw-light;
  color: $c-gray-light;
}

::v-deep .info-icon {
  fill: $c-orange;
}

.index-container {
  align-items: center;
  justify-content: center;
  display: flex;
  color: $c-orange;
  font-weight: bold;
  font-size: 1.5em;

  @media only screen and (max-width: 1024px) {
    align-items: start;
    justify-content: left;
  }
}

.input-trash__icons {
  fill: $c-orange;
  cursor: pointer;
}
