
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        




























.files-upload .vue-file-agent.file-input-wrapper {
  border: none;
  margin-top: 50px;
  border-radius: 20px;
  box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 5%);
  padding: 15px;
}
.vue-file-agent .file-preview-new::before,
.theme-list .vue-file-agent .file-preview-wrapper .file-preview::before {
  background: rgba(232, 78, 15, 0.2);
}
.label-files-upload {
  color: #9f9f9f;
}
