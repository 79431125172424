
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        


































































.edit__types__meters {
  align-items: center;
  border-right: 1px solid $c-gray-dark;
  display: flex;
  margin: 10px 0 0 0;
  padding: 0px 3% 0px 2%;
  &:last-child {
    border-right: 0;
  }
}

@media screen and (max-width: 960px) {
  .edit__types__meters {
    border-right: 0;
  }
}
.edit__types__meter__icon {
  width: 4.8rem;
  height: 4.8rem;

  &.gaz {
    fill: #5dad98;
  }

  &.elec {
    fill: #f7a600;
  }
}

.edit__types__meter__info {
  font-size: 0.9em;
  margin-left: 2.5rem;
}

.num-compt {
  text-transform: uppercase;
}
.tiny-2x {
  font-size: 0.8em;
}
