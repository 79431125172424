
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

















































































































































































.check-block--tiny-title {
  margin-right: 20px;
  font-family: $ff-default;
}

.row__switcher {
  display: flex;
  align-content: center;
}

.check-block {
  border-radius: 10px;
  padding: 20px;
  ::v-deep {
    .content__copy .content__copy__title.h4 {
      font-family: $ff-alt;
      font-size: 18px;
      font-weight: normal;
      line-height: 1.5em;
    }
  }
  &.is-active {
    border: 1px solid $c-orange;
    ::v-deep {
      .content__copy .content__copy__title.h4 {
        color: $c-orange;
      }
    }
  }
}

.connection-page .switcher {
  ::v-deep {
    margin-left: 2em;
    margin-top: -0.7em;
    margin-bottom: 1em;

    .list__item {
      font-size: 13px;
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}
.connection-page .switcher {
  ::v-deep {
    .slider {
      border-radius: 3px;
    }
  }
}
