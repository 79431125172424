
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        




















































































.check-blocks {
  justify-content: space-between;
  flex-direction: column;
  @include mq(m) {
    gap: 4rem;
    flex-direction: unset;
  }

  .check-block {
    flex: 1;

    ::v-deep {
      .content__copy__title.h4 {
        font-size: 1.8rem;
        font-weight: normal;
      }
    }
  }
}

.content {
  justify-content: space-between;
}

.tip {
  padding-top: unset;
  @include mq(m) {
    padding-top: 4rem;
  }
}
.tip__icon {
  @include mq(m) {
    margin-bottom: 1px;
  }
  fill: $c-main-primary;
  width: 1.6rem;
  height: 1.6rem;
}
