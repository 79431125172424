
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        













































.content {
  position: relative;
}

.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form {
  margin-bottom: 11rem;
  @include mq(m) {
    margin-bottom: 7rem;
  }
}

.error {
  margin-bottom: 2rem;
}

.inputs {
  display: inline-block;
  margin: 4rem 0 7rem;
  padding: 3.5rem 3.5rem 1.5rem 3.5rem;
  border-radius: 2rem;
  box-shadow: $box-shadow;

  @media screen and (max-width: 560px) {
    width: 100%;
    height: 100%;
  }
}

.inputs-inner {
  display: inline-block;
  width: 100%;
}

.submit {
  @include loading($c-orange);
  @media screen and (max-width: 560px) {
    width: 100%;
    height: 100%;
  }
}

.nav {
  text-align: right;
}
