
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        






























































.input-unit {
  display: flex;
  align-items: end;

  > * {
    flex-grow: 1;
  }
}

.info-wrapper {
  margin-right: 100px;
}

.input-unit__bubble {
  display: inline-block;
  margin-left: -9.5rem;
  //margin-top: 2.9rem;
  margin-top: 40px;
  //position: relative;
  position: absolute;
  span {
    background: #ffffff;
    //border: 1px solid #e8e8e8;
    box-sizing: border-box;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    color: #727272;
    font-size: 14px;
    position: absolute;
    padding: 10px 10px;
    width: 250px;
    z-index: 1;
    @include mq(s) {
      width: 400px;
    }
  }
  &::before {
    /*border-bottom: 1.0rem solid red;
    border-right: 1.0rem solid transparent;
    border-top: 0;
    border-left: 1.0rem solid transparent;
    */
    background-image: url('../../assets/images/arrow-up-bubble.png');
    background-size: 30px 20px;
    background-repeat: no-repeat;
    background-position: bottom;
    height: 20px;
    width: 30px;
    content: ' ';
    display: inline-block;
    margin-left: 7.5rem;
    margin-top: -20px;
    position: absolute;
    z-index: 10;
  }
}

.input-unit__label {
  // @extend %fw-medium;
  flex-basis: 80%;
  @include fluid(
    font-size,
    (
      xxs: 13px,
      m: 18px,
    )
  );

  display: inline-flex;
  align-items: center;
}

.input-unit__icons {
  fill: $c-gray-darker;
  margin-left: 10px;
}

.input-unit__data {
  position: relative;
  display: flex;
  align-items: center;
  flex-basis: 60%;
  border-bottom: 1px solid $c-gray-darker;

  .input-unit__field {
    background: transparent;
    width: 100%;
    font-size: 1.8rem;
    padding: 0.5rem 0 0.3rem;
    border: 0;

    &::placeholder {
      @extend %fw-light;

      color: $c-gray-light;
    }

    &:disabled {
      padding-left: 0.5em;
      color: darken($c-gray-light, 10%);
      background: lighten($c-gray-light, 10%);

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  span {
    @include fluid(
      font-size,
      (
        xxs: 13px,
        m: 15px,
      )
    );
    color: $c-gray-darker;
  }
}

.form-error {
  color: $c-red !important;
}
