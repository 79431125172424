
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



























































@import '~leaflet/dist/leaflet.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';

.intro {
  margin-top: 3rem;
  margin-bottom: 3rem;

  @include mq(m) {
    margin-top: 15rem;
    margin-bottom: 12rem;
  }
}

.controls {
  border-top: 1px solid $c-gray-light;
}

.controls-inner {
  display: flex;
  align-items: center;
  padding-left: col(2);

  @include mq(m) {
    padding-left: 3rem;
  }
}

.search {
  position: relative;
  flex: 1;
  margin-right: 2rem;
  padding: 2rem 0;

  .input {
    padding-right: 3rem;
  }
}

.submit {
  position: absolute;
  top: 50%;
  right: 0;
  width: 2.4rem;
  height: 2.4rem;
  padding: 0;
  background: transparent;
  border: 0;
  transform: translateY(-50%);

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.filter {
  position: relative;
  display: none;
}

.filter-trigger {
  display: flex;
  align-items: center;
  margin-top: -1px;
  padding: 2rem 3rem;
  color: $c-white;
  background: $c-orange;
  border: 0;

  span {
    margin-right: 1.5rem;
    font-size: 1.3rem;
  }

  svg {
    width: 1.4rem;
    height: 1rem;
    fill: $c-white;
  }
}

.filter-list {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  height: 5rem;
}

.error {
  padding-left: 3rem;
}

.map {
  z-index: 1;
  width: 100%;
  // height: 100%;
  height: 67rem;
  max-height: 74.5rem;
  background: $c-gray-lightest url('/assets/images/loader.svg') no-repeat center
    center;

  ::v-deep {
    .leaflet-pane {
      z-index: 1;
    }
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (373 / 840) * 100%;
  }
}
