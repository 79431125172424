
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        






























































































































































































.info__green {
  background-color: #f5f8f7;
  padding: 25px;
  margin-bottom: 30px;
  border-left: 4px solid $c-dark-green;
}
svg {
  fill: $c-dark-green;
}

.captcha-form {
  margin-top: 5rem;
}
.text-small {
  font-size: 1.1rem;
  margin-bottom: 2rem;
}
.checkBlock-container {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
}
.success {
  color: #5dae99;
}
.error {
  color: red;
}
.content {
  margin-bottom: 50px;
}
.situation {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}
.situation-input {
  width: 45%;
  margin-bottom: 50px;
}
.situation-input-name {
  width: 45%;
}
.situation-container {
  width: 100%;
  h2 {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  h3 {
    color: #e84e0f;
    font-size: 30px;
    line-height: 1.1;
    font-weight: inherit;
  }
  h2 {
    font-size: 18px;
    line-height: 1.1;
    font-weight: bold;
  }
  h1 {
    color: $c-dark-green;
    display: flex;
    font-size: 3rem;
    font-weight: inherit;
    text-transform: inherit;
    font-family: proxima-nova, sans-serif;
    line-height: 1.1;
    margin-left: 5px;
  }
  .title {
    display: flex;
    margin-bottom: 50px;
  }
}

.flex {
  display: flex;
  align-items: center;

  @media only screen and (max-width: 960px) {
    display: block;
  }
}
