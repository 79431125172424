
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        









































































.notification-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 3rem;

  .icon {
    display: block;
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 1rem;
  }
}

.notification-title {
  line-height: 1;
  margin: 0;
}
.fs-h5 {
  font-size: clamp(2rem, 4.5vw, 2.2rem);
}

.notification-content {
  color: #333333;
}
