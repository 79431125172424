
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





































































































































































































































































.switcher-container {
  display: flex;

  p {
    width: 40em;
  }

  @media screen and (max-width: 720px) {
    display: block;

    p {
      width: 100%;
    }
  }
}

.switcher {
  margin-left: 7em;

  ::v-deep {
    @media screen and (max-width: 720px) {
      margin-left: 0 !important;
      margin-top: 1em;
    }
  }
}

.encode-trajets-datas {
  ::v-deep.info-icon {
    fill: $c-orange;
  }

  ::v-deep .input__label {
    font-size: 1.8rem;
  }
}

.message-alert {
  color: $c-orange;
  font-size: 1.8rem;
  font-weight: bold;
}
