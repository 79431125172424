
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        







































































































.customul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.customli {
  border-radius: 10px;
  background: white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.28);
  padding: 20px 25px;
  margin-bottom: 30px;
}

.customa {
  margin-bottom: 10px;
  display: inline-block;
}

.faqli {
  margin-bottom: 10px;
}

.faqli .right-arrow {
  margin-right: 10px;
  color: $c-orange;
}

.icons__energy {
  justify-content: center;
  align-items: center;
  display: inline-flex;

  svg {
    margin-right: 10px;
  }
}

.switches button.btn {
  background-color: $c-white;
  border: 1px solid $c-orange;
}

.switches button.btn:disabled {
  opacity: unset;
  background-color: $c-orange;
}

.table-pagination {
  max-width: 65%;
  justify-content: center;
  align-items: center;
}

.help-container {
  border-radius: 30px;
  padding: 20px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 28%);
}

@media only screen and (max-width: 63.99em) {
  .search-view ul {
    max-width: 100%;
  }
  .table-pagination {
    max-width: 100%;
    justify-content: space-between;
  }
  .table-pagination .btn {
    padding: 5px 10px;
  }
}
