
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        










































































































































.size__16 {
  font-size: 16px;
}

.color__green {
  color: $c-green;
}

.color__red {
  color: $c-red;
}

.color__orange {
  color: $c-orange-light;
}

.connection__message {
  display: flex;
  font-size: 16px;

  .check__svg {
    fill: $c-green;
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 0.5rem;
  }
  .error__svg {
    fill: $c-red;
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 0.5rem;
  }

  .checkerror__svg {
    fill: $c-orange-light;
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 0.5rem;
  }
}

.icons__energy {
  justify-content: center;
  align-items: center;
  display: inline-flex;

  svg {
    margin-right: 10px;
  }
}
