
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        







































































































































































































































.step-box-area {
  border-radius: 20px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.15);
  padding: 2em;
  margin-left: 80px;

  .panel-accordion-arrow {
    display: none;
  }

  @media screen and (max-width: 1440px) {
    margin-top: 1em;
    margin-left: 0;
    flex-basis: 100%;
    max-width: 100%;

    .box-title {
      display: flex;
      align-items: center;

      .panel-accordion-arrow {
        display: flex;
        fill: $c-orange;
        margin-left: auto;
        flex: 0 0 auto;
      }
    }
  }

  .text-info {
    color: $c_orange;
    margin-top: 5px;

    span {
      color: $c_black;
    }

    .content__icon {
      max-width: 4rem;
      max-height: 4rem;
      margin-right: 1.1rem;

      &.icons-day,
      &.icons-night {
        fill: $c-orange;
      }
    }
  }

  .text-value {
    color: $c-black;
    margin-left: 2em;
    margin-top: 0.5em;
  }

  &.mobile {
    display: none;

    @media screen and (max-width: 1440px) {
      display: block;
      margin-top: 35px;
      padding-top: 1em;
      padding-bottom: 0.5em;

      &.open {
        padding-bottom: 2em;
      }
    }
  }

  &.desktop {
    display: block;

    @media screen and (max-width: 1440px) {
      display: none;
    }
  }
}
