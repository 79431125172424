
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        




























































.connection__main {
  @media screen and (max-width: 760px) {
    padding-top: 4rem;
  }
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 3px solid $c-black;
}

.connection-page .nav {
  border-top: 1px solid #bdbdbd;
}

.nav__back {
  ::v-deep {
    .action__label {
      color: $c-gray;
    }

    .icon {
      margin-right: 0;
      fill: $c-gray;
    }
  }
}

.nav__forward {
  border-radius: 20px;
  margin-left: auto;
  padding: 1.5rem 2.2rem;
}
.connection-page {
  display: flex;
  margin: 20px;
  ::v-deep {
    .switcher {
      border-radius: 5px;
      padding: 3px 5px;
      margin-left: 2em;

      @media screen and (max-width: 960px) {
        margin-left: 0em;
      }
    }

    @media screen and (max-width: 960px) {
      margin: 1em 0 0 0;
    }
  }
}

.connection-page {
  ::v-deep {
    .switcher .slider {
      border-radius: 3px;
    }
  }
}

.connection {
  margin-top: 0px;
}

.connection-page .connection {
  padding-right: 1%;
}

.stepButton2,
.stepButton3 {
  width: 100%;
  @media screen and (max-width: 760px) {
    padding-right: 10%;
  }
}

::v-deep {
  .checkbox {
    .label::before {
      border: 0.1rem solid $c-gray-darkest;
      height: 1.8rem;
      margin-top: -2px;
      width: 1.8rem;
    }
  }

  .breadcrumb {
    padding-top: 0;
    @media screen and (max-width: 760px) {
      padding-top: 3.5rem;
    }
  }

  .check-block_item {
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
  }

  .check-block {
    padding-bottom: 3%;
    padding-top: 3%;
    @media only screen and (max-width: 760px) {
      padding-bottom: 6%;
      padding-top: 6%;
    }
  }

  .check-block-group {
    @media only screen and (max-width: 760px) {
      padding-bottom: 0;
    }
  }

  .checks-block {
    padding-bottom: 0px;
    width: auto;
  }

  .check-block .is-active {
    color: $c-orange;
    border-color: $c-orange;
  }
  .check-block .is-active .content .content__copy .content__copy__text {
    color: #e84e0f;
  }

  .check-block .content .content__copy {
    align-items: center;
    padding-right: 2rem;
  }

  h1.span-elec {
    padding-left: 50px;
    min-height: 45px;
    background: left center url('../../assets/svg/icons/electricityflood.svg')
      no-repeat;
    background-size: 45px 45px;
    line-height: 45px;

    @media screen and (max-width: 760px) {
      background: none;
      padding-left: 0px;

      line-height: 1.2em;
    }
    @media screen and (max-width: 1200px) {
      line-height: 1.2em;
    }
  }

  .electricite__header__icon {
    overflow: visible;

    width: 40px;
    height: 40px;
    display: none;
    @media screen and (max-width: 760px) {
      display: inline-block;
    }
  }
}
