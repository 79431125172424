
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        























































































































































































































































































































































































.equipment {
  align-items: center;

  &.with-border {
    border-bottom: 1px solid $c-gray;
    padding-bottom: 2em;
  }
}

.equipment-label {
  font-size: 1.1em;

  @media screen and (max-width: 720px) {
    font-size: 1.5em;
  }
}

.equipment-power {
  color: $c-orange;

  @media screen and (max-width: 720px) {
    font-size: 1.2em;
    margin-top: 1em;
  }
}

.equipment-periods {
  &__label {
    color: $c-orange;
  }

  @media screen and (max-width: 720px) {
    width: 100%;
    margin-bottom: 0.8em;
    font-size: 1.2em;
    margin-top: 1em;
  }

  .check-block-container {
    display: flex;
  }

  .check-block {
    margin-right: 1em;
    padding: 1em;
    margin-top: 1em;

    @media screen and (max-width: 720px) {
      margin-right: 0.5em;
      width: 100%;
    }
  }

  ::v-deep .check-block.is-active {
    border-color: $c_orange;
    color: $c-orange;

    .content__icon {
      fill: $c-orange;
    }
  }

  ::v-deep .check {
    margin-left: 1em;
  }
}
