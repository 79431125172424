
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



































































.title {
  color: $c-main-secondary;
  font-size: 35px;
  font-weight: 600;
  @include mq(m) {
    font-size: 55px;
  }
}

.confirm-text {
  display: flex;
  color: $c-green;
  fill: $c-green;
}

.confirm-follow {
  @include mq(m) {
    display: flex;
  }
}

.confirm {
  color: $c-main-primary;
}

.button {
  margin-left: auto;
  display: flex;
  border-radius: 20px !important;
  height: fit-content;
  padding: 1.5rem 2rem;
}

.svg-confirm {
  min-width: 20rem;
  width: 20rem;
  height: 20rem;

  @include mq($until: m) {
    display: flex;
    margin-right: auto;
    margin-left: auto;
  }
}

.buttons-form {
  border-top: 1px solid #d3d4d8;
}

.button-return {
  border-radius: 20px !important;
}
