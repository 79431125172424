
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





























.headband {
  display: flex;
  box-shadow: 0px 0px 24px rgba(24, 33, 77, 0.2);
  border-radius: 20px;
  padding: 2rem 2rem;
  width: 100%;

  h3 {
    font-weight: normal;
    color: #575756;
  }

  .address {
    line-height: 2rem;
  }

  .icon-container {
    min-width: fit-content;
  }

  .icon-box {
    display: grid;
    background-color: #f0f1f3;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    align-items: center;
    justify-items: center;
  }

  .icon {
    fill: $c-main-primary;
  }

  .contents {
    width: 100%;
    @include fluid(
      margin-left,
      (
        xxs: 10px,
        xl: 50px,
      )
    );
    @include mq(xs) {
      display: flex;
    }
  }

  .text-content {
    align-content: center;
    display: grid;
    @include mq($until: xs) {
      margin-bottom: 15px;
    }
  }

  .button-container {
    display: flex;
    margin-left: auto;
    align-items: center;
  }

  .button {
    height: fit-content;
    padding: 1.5rem 2rem;
    border-radius: 20px;

    @include mq($until: xs) {
      width: 100%;
    }
  }
}
