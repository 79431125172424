
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



















































































.check-luminaire {
  width: 100%;
}

.city {
  margin-right: 30px;
}

.button {
  border-radius: 20px !important;
  height: fit-content;
}

.input-unit__icons {
  cursor: pointer;
  fill: $c-gray-darker;
}

.form-error {
  color: $c-red !important;
}
