
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        







.capacites-permanentes__main {
  ::v-deep .wrapper {
    margin-right: 0;
    margin-left: 0;
  }
}
