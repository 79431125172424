
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        








































.checkBlock-container {
  ::v-deep span {
    font-size: 1.7rem;
    color: $c-gray-darker;
    font-weight: 400;
    line-height: 1.5;
  }

  ::v-deep .link-block {
    text-align: left;
  }

  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
}
.content {
  margin-bottom: 50px;
}
.situation-container {
  width: 100%;

  h2 {
    margin-bottom: 20px;
  }
  h3 {
    margin-bottom: 20px;
  }
  h1 {
    font-size: 1.5em;
    font-weight: normal;
    margin-bottom: 2.5rem;
    text-transform: none;
  }
  h2 {
    color: #e84e0f;
    font-size: 20px;
    text-transform: uppercase;
  }

  .mandatory {
    color: $c-orange;
    font-weight: bold;
  }
}
.info__green {
  background-color: $c-light-green;
  padding: 25px;
  margin-bottom: 30px;
  border-left: 4px solid $c-dark-green;
  width: 60%;

  .blockTitle {
    color: $c-dark-green;
    display: flex;

    .text {
      font-size: 1.5em;
      margin-left: 1em;
    }

    svg.install-info__icon {
      fill: $c-dark-green;
    }
  }

  @media screen and (max-width: 960px) {
    width: 100%;
  }
}
