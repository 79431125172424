
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        











































































































































































































































































































































.bold {
  font-weight: bold;
}

.types__item {
  &:not(:last-child) {
    margin-right: 1rem;
  }
}

.link-block {
  border-radius: 10px;
  padding: 30px 40px;
  ::v-deep {
    .label {
      font-family: $ff-alt;
      font-size: $body-text-m;
      font-weight: bold;
    }
  }
  &.is-selected {
    background-color: $c-orange;
    ::v-deep {
      .label {
        color: white;
      }
    }
  }

  @media screen and (max-width: 720px) {
    width: 100%;
    margin-right: 0 !important;
    justify-content: center;
  }
}
.phone {
  display: flex;
  width: 100%;
  color: $c-gray;

  ::v-deep .input {
    width: 100%;
  }
}
