
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        
























































































.jobs {
  position: relative;

  ::v-deep .breadcrumb {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }
}

.gallery {
  max-width: $content-width;
  margin-right: auto;
  margin-left: auto;
}

.gallery__item {
  position: relative;

  &.last {
    margin-top: -3%;
    margin-bottom: 3%;
  }

  img {
    @include image-fit;
  }

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.links {
  position: relative;

  &.is-loading {
    &::after {
      content: '';
      display: block;
      height: 15rem;
      background: $c-white url('/assets/images/loader.svg') no-repeat center;
    }
  }
}

.links__list {
  margin: 0;
  padding: 0;
  transition: opacity 0.3s;

  .is-loading & {
    opacity: 0;
  }
}

.links__list__item {
  @extend %border-radius;

  list-style-type: none;
  background: $c-white;
  border: 1px solid $c-gray-light;
  transition: all 0.3s;

  &:hover {
    border: 1px solid $c-white;
    box-shadow: 0 10px 30px rgba($c-black, 0.1);
    transform: translateY(-2px);
  }

  ::v-deep [class*='action--link'] {
    // prettier-ignore
    @include fluid(padding-top, (xxs: 20px, m: 30px));
    // prettier-ignore
    @include fluid(padding-right, (xxs: 20px, m: 40px));
    // prettier-ignore
    @include fluid(padding-bottom, (xxs: 20px, m: 30px));
    // prettier-ignore
    @include fluid(padding-left, (xxs: 20px, m: 40px));

    display: flex;
    justify-content: space-between;
  }
}

.cta {
  border: $c-gray-light 1px solid;
}
