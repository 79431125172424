
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



























































































































.contact-formulaire div.select-accordion {
  border-radius: 10px;
  margin-bottom: 60px;
}

.contact-formulaire div.select-accordion div.select-accordion-title:hover span {
  font-weight: normal;
}

.contact-formulaire
  div.select-accordion
  div.select-accordion-title.active
  .level-name {
  font-weight: normal;
}

.contact-formulaire div.select-accordion div.select-accordion-content {
  padding: 15px;
}

.contact-formulaire div.select-accordion-content .level-list-item {
  margin-bottom: 5px;
  cursor: pointer;
}

.contact-formulaire div.select-accordion-content .level-list-item a {
  color: #575756;
}

div.select-accordion {
  margin-top: 25px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid lightgrey;
  border-radius: 15px;
}

div.select-accordion div.select-accordion-title {
  padding-top: 15px;
  padding-left: 20px;
}

div.select-accordion div.select-accordion-title:hover {
  cursor: pointer;
}

div.select-accordion div.select-accordion-title.active {
  border-bottom: 1px solid lightgray;
}

.level-name {
  color: #575756;
}

.default-placeholder {
  color: #9f9f9f;
}

div.select-accordion div.select-accordion-title:hover span {
  font-weight: bolder;
}

div.select-accordion div.select-accordion-title.active .level-name {
  font-weight: bolder;
}

div.select-accordion div.select-accordion-title svg {
  fill: $c-orange;
  float: right;
  margin-top: -10px;
  transform: none;
}
