
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        























































































































































































































































::v-deep {
  #possibleRemark {
    box-shadow: 0px 2px 2px 0px #00000026;
    border-radius: 20px;
    padding: 15px 20px;
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    height: 150px;
  }
  #possibleRemark::placeholder {
    color: #1111117a;
  }
}

.complianceReport {
  position: relative;
  display: flex;

  input {
    @extend %visually-hidden;

    border: 0;

    &:checked + label::after {
      display: block;
    }
    &:checked + .text::after {
      display: block;
    }
  }
}

.connection-documents {
  ::v-deep {
    #Rue {
      padding-top: 0.3rem;
    }

    .upload__help {
      color: #1111117a;
      font-size: 18px;
      font-weight: 300;
    }

    .vue-file-agent.file-input-wrapper {
      box-shadow: 0px 2px 2px 0px #00000026;
      border-radius: 20px;
    }
    [helperclass='active-sorting-item'] {
      text-align: left;
      width: 100%;
      > div {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        /*width: 400px;*/
        width: 100%;
        padding-left: 15px;
        .file-name {
          font-weight: normal !important;
          margin-top: 5px;
          margin-left: 0;
          padding-left: 0;
        }
      }
    }
    .file-delete {
      width: auto;
      svg {
        background-color: #d3d4d8;
        height: 30px !important;
        width: 30px !important;
      }
    }
  }
  .mandatory {
    color: $c-orange;
  }
  .file-progress.file-progress-done {
    width: 0px !important;
  }
  .info__wrapper {
    padding: 20px 40px 20px 10px;
    .info__svg {
      fill: #ff4b00;
      height: 40px;
      width: 40px;
      margin: 0 20px;
    }
    .info__text {
      font-size: 16px;
    }

    @media screen and (max-width: 720px) {
      padding: 20px 20px 20px 0;
    }
  }
}
.phone {
  display: flex;
  width: 100%;
  color: $c-gray;
  ::v-deep .input {
    width: 100%;
  }
}
.switcher--dual {
  /*width: 14.5rem;*/
}
.span-elec {
  padding-left: 30px;
  background: left center url('../../assets/svg/icons/electricityflood.svg')
    no-repeat;
  background-size: contain;
}
.checkAddress {
  padding-top: 3rem;
  margin-bottom: 0.5rem;
}
.bottom-espace {
  margin-bottom: 50px;
}
.check-block {
  margin-bottom: 5rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 4%;
  padding-top: 4%;
  justify-content: start;
  @media only screen and (max-width: 760px) {
    flex-direction: column;
    margin-bottom: 2rem;
    width: 100%;
  }

  ::v-deep {
    .content {
      max-width: 14rem;
    }

    .content .content__copy .content__copy__text {
      padding-bottom: 10px;
    }
  }
}

.checks-block {
}

.check-block_item {
  min-width: 28rem;
  margin-right: 2rem;
  width: 30%;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 760px) {
    width: 100%;
  }
}

.situation-container {
  h2 {
    margin-bottom: 20px;
  }
  width: 100%;
  @media only screen and (max-width: 760px) {
    width: 90%;
  }
}
.situation-input {
  width: 45%;
  margin-bottom: 50px;
  @media only screen and (max-width: 760px) {
    width: 100%;
    margin-bottom: 2rem;
  }
}
.situation-input-name {
  width: 45%;
  @media only screen and (max-width: 760px) {
    width: 100%;
    margin-top: 2rem;
  }
}
.situation-input-container {
  width: 100%;
}
.situation {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  @media only screen and (max-width: 760px) {
    flex-direction: column;
  }
}
.address {
  width: 100%;
}

h1 {
  font-size: 24px;
  line-height: 1.1;
  margin-bottom: 5rem;
  font-weight: bold;
}
h2 {
  color: #e84e0f;
  font-size: 20px;
  line-height: 1.1;
  font-weight: bold;
  text-transform: uppercase;
}
.flex {
  display: flex;
  align-items: center;
  @media only screen and (max-width: 760px) {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    div {
      margin-top: 50px;
    }

    div.valid-billing-addres {
      padding-bottom: 1.5rem;
      margin-top: 0;
    }

    div.valid-billing-addres,
    div.switcher {
      margin-top: 0;
    }
  }
}
div.bottom-espace {
  margin-bottom: 2rem;
}
