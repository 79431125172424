
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        
















.list {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  //counter-reset: count;

  @media screen and (max-width: 960px) {
    display: block;
  }
}

.list__item {
  // prettier-ignore
  @include fluid(font-size, (xxs: 13px, m: 15px));
  position: relative;
  overflow: hidden;
  margin-right: 1rem;
  padding-bottom: 0.5rem;
  color: $c-gray-light;
  // counter-increment: count;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 2px solid $c-gray-light;
  transition: all 0.4s $ease-out-quad;
  flex: 1 1;

  @media screen and (max-width: 960px) {
    display: none;
  }

  &::before {
    @extend %fw-bold;

    // content: counter(count);
    margin-right: 1rem;
  }

  &.is-current {
    flex: 1;
    color: $c-orange;
    border-bottom: 2px solid $c-orange;

    @media screen and (max-width: 960px) {
      display: block;
    }
  }
}
